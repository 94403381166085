import axios from "axios"

export default {
    namespaced: true,
    actions: {
        getLokasi({ }, latlong) {
            return new Promise((resolve, reject) => {
                axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlong}&key=AIzaSyCDjPvbJYcwPK7VeucO5YcYibGYqxUGLIU`)
                    .then((res) => {
                        if (res.data.status === "REQUEST_DENIED") {
                            reject(res.data.error_message)
                        } else {
                            resolve(res.data)
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
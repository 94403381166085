<template>
  <div class="pb-12">
        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="overflow-hidden bg-white">
                
                <div class="p-6 mx-auto prose lg:prose-xl prose-h2:text-gray-600">
                    <router-link class="text-medium-blue" to="/">
                    <img src="@/assets/mobooking-logo-blue.png" width="300" alt="Logo" />
                </router-link>
                    <h2>
                        Kebijakan Privasi
                    </h2>

                    <p class="text-sm">Berlaku sejak 14 Februari 2022</p>
                    <p class="font-bold">Kebijakan Privasi Mobooking</p>
                    <p>Kami peduli terhadap kerahasiaan data Anda.</p>
                    <ol>
                        <li>Tentang</li>
                        <li>Hak dan kontrol data pribadi Anda</li>
                        <li>Pengumpulan data pribadi Anda</li>
                        <li>Tujuan penggunaan data pribadi Anda</li>
                        <li>Berbagi data pribadi Anda</li>
                        <li>Penghapusan data</li>
                        <li>Menjaga keamanan data pribadi Anda</li>
                    </ol>
                    <p class="font-bold">1. Tentang Kebijakan Privasi</p>
                    <p>Kebijakan ini menjelaskan bagaimana kami memproses data pribadi Anda di Mobooking. Kebijakan ini bukan Syarat Penggunaan Mobooking. Syarat Penggunaan merupakan dokumen terpisah yang menjelaskan peraturan Mobooking dan hak pengguna Anda, sekaligus kontrak hukum antara Mobooking dan Anda dalam menggunakan Layanan Mobooking.</p>
                    <p>Kebijakan ini berlaku untuk penggunaan Anda atas seluruh Layanan Mobooking sebagai pengguna akhir, termasuk penggunaan layanan Aplikasi/Fitur dan/atau Platform Merchant kami (masing-masing disebut "Pilihan Layanan"); dan layanan Mobooking lainnya yang menyertakan tautan ke Kebijakan Privasi ini (misalnya website Mobooking dan Syarat Penggunaan). Mulai sekarang, kami secara bersama-sama menyebutnya "Layanan Mobooking".</p>
                    <p>Kami dapat mengembangkan layanan baru dan menawarkan layanan tambahan dari waktu ke waktu sesuai kebutuhan. Layanan-layanan tersebut akan tunduk pada Kebijakan Privasi ini.</p>
                    
                    <p class="font-bold">2. Hak dan Kontrol Pribadi Anda</p>
                    <p>
                        Kerahasiaan data Anda masuk dalam Peraturan Menteri No 20 tahun 2016 tentang Perlindungan Data Pribadi <b>(PDP)</b>, yang melindungi dan memberikan hak kepada individu atas data pribadi Anda. 
                    </p>
                    <ol type="a">
                        <li>Akses<br/> Anda berhak diberi tahu mengenai data pribadi yang kami kumpulkan dan proses dari Anda, dan meminta akses terhadap data tersebut.</li>
                        <li>Pembaruan<br/> Anda berhak mengajukan permohonan untuk mengubah atau memperbarui data jika ditemukan data tidak akurat.</li>
                        <li>Pembatasan<br/> Anda berhak mengajukan permohonan kepada kami agar berhenti memproses seluruh atau sebagian data pribadi Anda secara permanen atau sementara.</li>
                        <li>Penghapusan<br/> Anda berhak meminta kami untuk menghapus data pribadi Anda</li>
                        <li>
                            Penolakan<br/> Anda berhak menolak pengumpulan atau pemrosesan data pribadi Anda oleh kami sewaktu-waktu berhubungan dengan kondisi khusus Anda.
                        </li>
                    </ol>
                    <p class="font-bold">3. Pengumpulan Data Pribadi Anda</p>
                    <p>Agar lebih mudah memahami, berikut kategori data pribadi Anda yang kami kumpulkan dan gunakan terhitung saat sejak Anda mendaftar dan memperbarui akun Mobooking.</p>
                    <ol type="a">
                        <li>
                            Data Pengguna<br/>
                            Data pribadi yang kami butuhkan untuk membuat akun Mobooking Anda sehingga memungkinkan Anda menggunakan layanan Mobooking:
                            <ul>
                                <li>Alamat email</li>
                                <li>Nama profil</li>
                                <li>Nomor telepon</li>
                                <li>Tanggal lahir</li>
                                <li>Jenis kelamin</li>
                            </ul>
                        </li>
                        <li>
                            Akses Perangkat<br/>
                            Kami membutuhkan izin Anda untuk memberikan akses lokasi geografis, menggunakan kamera untuk mengambil gambar,merekam video, membuka galeri, dan dokumen.
                            <ul>
                                <li>
                                    Lokasi Geografis<br/>
                                    Mobooking harus mendapatkan informasi mengenai lokasi Anda guna memaksimalkan kerja fitur layanan terdekat dan relevan bagi Anda, juga mengoptimalkan pelacakan dan
                                </li>
                                <li>
                                    Kamera dan Galeri<br/>
                                    Mobooking akan meminta izin Anda untuk mengakses kamera dan galeri untuk keperluan scan QR code saat kegiatan kumpul bersama, mengunggah dokumen untuk melengkapi profil dan tes lab pada deteksi mandiri. Mobooking tidak akan mengambil data, informasi, atau dokumen lain selain yang Anda pilih atau izinkan
                                </li>
                            </ul>
                            <p>
                                Anda dapat membatalkan izin terhadap akses pada poin 2 yang digunakan oleh Mobooking dengan mengubah melalui pengaturan perangkat Anda.
                            </p>
                        </li>
                        <li>
                            Pihak Ketiga<br/>
                            <ul>
                                <li>
                                    Mitra Layanan<br/> Teknis Kami bekerjasama dengan mitra layanan teknis yang menyediakan informasi lokasi hingga data tertentu guna memungkinkan Mobooking memberikan Layanan dan Fitur-Fitur Mobooking.
                                </li>
                                <li>
                                    Mitra Pembayaran<br/> Dalam pengembangan kedepannya, kami juga akan bekerjasama dengan mitra pembayaran. Jika Anda memilih salah satu dari beberapa mitra pembayaran yang tersedia untuk melakukan transaksi di Mobooking, maka kami akan dapat mengirimkan Anda tagihan, memproses pembayaran, dan memberikan apa yang Anda beli kepada Anda.
                                </li>
                                <li>
                                    Mitra Otentikasi <br/>Ketika Anda mendaftar atau membuat akun Mobooking menggunakan layanan lain, maka kami akan mengambil informasi Anda dari layanan tersebut.
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <p class="font-bold">4. Tujuan Penggunaan Data Pribadi Anda</p>
                    <p>
                        Saat Anda berhasil mendaftar dan membuat akun Mobooking,  sistem akan meminta persetujuan Anda untuk mengaktifkan lokasi GPS untuk memberikan Anda linformasi terkait lokasi Anda dan Layanan Mobooking yang dipersonalisasi sesuai dengan radius lokasi Anda.
                    </p>
                    <p>
                        Data lokasi Anda secara yang aktif secara periodik akan ditelusuri oleh sistem Mobooking dan terekam dalam histori aktivitas Anda, termasuk ketika Anda melakukan kegiatan kumpul bersama. Kumpulan data ini akan memudahkan kami dan <span class="text-blue">[Nama Badan/Kementrian yg kerjasama dg Mobooking]</span> memonitor penyebaran COVID-19 dan mempersiapkan hal-hal preventif sebagai bentuk mitigasi risiko bagi masyarakat umum untuk meminimalisir dampak penyebaran yang merugikan berbagai pihak.
                    </p>
                    <p class="font-bold">5. Berbagi Data Pribadi Anda</p>
                    <p>
                        Data pribadi Anda tidak akan dibagikan kepada pihak lain kecuali pihak - pihak tertentu yang bertanggung jawab dalam mitigasi penyebarluasan COVID-19 dengan tujuan mengakomodir tatanan new normal, dalam hal ini adalah <span class="text-blue">[Nama Badan/Kementrian yg kerjasama dg Mobooking]</span> termasuk permintaan yang sah dari aparat penegak hukum atas dasar ketentuan perundang-undangan yang berlaku. Jika ada keperluan untuk memberikan kepada pihak selain yang disebutkan, Mobooking akan meminta persetujuan Anda dan melakukan verifikasi untuk memastikan keakuratan data Anda.
                    </p>
                    <p class="font-bold">6. Penghapusan Data</p>
                    <p>
                        Data Anda akan otomatis terhapus dari server Mobooking minimal <span class="text-blue">[n tahun]</span> sejak Mobooking tidak beroperasi. Jika Anda berkehendak ingin menghapus lebih awal, silakan sampaikan permohonan melalui email <span class="text-blue">kontak@mobooking.id</span>
                    </p>
                    <p class="font-bold">7. Menjaga Keamanan Data Pribadi Anda</p>
                    <p>
                        Kami berkomitmen untuk melindungi data pribadi Anda sebagai salah satu bentuk kewajiban kami terhadap pengguna Mobooking. Kami menerapkan langkah-langkah fungsional dan organisasional yang sepatutnya untuk melindungi keamanan data pribadi Anda; akses, enkripsi, dan penyimpanan untuk memberikan perlindungan terhadap akses tanpa izin dan penyimpanan data pribadi yang tidak diperlukan dalam sistem Mobooking.
                    </p>
                    <p>Demi meningkatkan perlindungan akun Mobooking Anda, kami menyarankan Anda untuk:</p>
                    <ul>
                        <li>Membuat kata sandi yang unik dan kuat untuk akun Mobooking Anda</li>
                        <li>Tidak membagikan kata sandi kepada siapa pun</li>
                        <li>Segera keluar dari akun Mobooking Anda jika menggunakan perangkat bersama</li>
                        <li>Batasi akses umum pada perangkat pribadi Anda</li>
                    </ul>
                    <p>Jika Anda memberikan akses akun Mobooking Anda kepada orang lain dengan consent atau persetujuan Anda, maka mereka dapat mengakses data pribadi dan kontrol akun Anda. Dalam hal ini, Anda bertanggung jawab atas aktivitas yang mereka lakukan.</p>
                    <!--
                    <p>Kerahasian Informasi Pribadi adalah hal yang terpenting bagi MOBOOKING. Kami berkomitmen untuk melindungi dan menghormati privasi pengguna saat mengakses dan menggunakan aplikasi, fitur, teknologi, konten dan produk yang Kami sediakan,</p>

                    <p>Kebijakan Privasi ini mengatur landasan dasar mengenai bagaimana Kami menggunakan informasi pribadi yang Kami kumpulkan dan/atau atau Anda berikan. Kebijakan Privasi ini berlaku bagi seluruh pengguna Platform, kecuali diatur pada Kebijakan Privasi yang terpisah. Mohon membaca Kebijakan Privasi Kami dengan seksama sehingga Anda dapat memahami pendekatan dan cara Kami dalam menggunakan informasi tersebut.</p>

                    <p>Kebijakan Privasi ini mencakup hal-hal sebagai berikut:</p>

                    <ul>
                        <li>Informasi Pribadi yang Dapat Kami Kumpulkan</li>
                        <li>Penggunaan Informasi Pribadi</li>
                        <li>Pengungkapan Informasi Pribadi</li>
                        <li>Penyimpanan Informasi Pribadi</li>
                        <li>Hak Anda</li>
                        <li>Kebijakan Cookies</li>
                        <li>Pengakuan dan Persetujuan</li>
                        <li>Materi Pemasaran</li>
                        <li>Perubahan dalam Kebijakan Privasi Kami</li>
                        <li>Cara Menghubungi Kami</li>
                    </ul>
                    -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>
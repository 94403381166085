import { createStore } from "vuex";
import auth from "./modules/auth";
import admin from "./modules/admin";
import role from "./modules/role";
import menu from "./modules/menu";
import sectionApp from "./modules/sectionApp";
import partner from "./modules/partner";
import user from "./modules/user";
import global from "./modules/global";
import kegiatan from "./modules/kegiatan";
import region from "./modules/region";
import merchant from "./modules/merchant";
import dashboard from "./modules/dashboard";
import maps from "./modules/maps";
import monitoring from "./modules/monitoring";
import penanganan from "./modules/penanganan";
import event from "./modules/event";
import kategori from "./modules/kategori";
import wilayah from "./modules/wilayah";
import payout from "./modules/payout";
import jenisusaha from "./modules/jenisusaha";
import setupmerchant from "./modules/setupmerchant";
import berita from "./modules/berita";
import banner from "./modules/banner";
import refund from "./modules/refund";

export default createStore({
  modules: {
    global,
    auth,
    admin,
    role,
    menu,
    sectionApp,
    partner,
    user,
    kegiatan,
    region,
    merchant,
    dashboard,
    maps,
    monitoring,
    penanganan,
    event,
    kategori,
    wilayah,
    payout,
    jenisusaha,
    setupmerchant,
    berita,
    banner,
    refund
  },
});

import RoleService from "@/services/roleService";

export default {
  namespaced: true,
  state: {
    listRoles: [],
    menu: [],
    modalRole: {
      isModalShow: false,
      currentData: null,
    },
    modalOtherMenu: {
      isModalShow: false,
      currentData: null,
    },
  },
  mutations: {
    getRoles(state, data) {
      state.listRoles = data;
    },

    createRole(state, data) {
      state.listRoles = data;
    },

    getMenu(state, data) {
      state.menu = data;
    },

    setEditDataModalRole(state, data) {
      state.modalRole.currentData = data;
    },

    toggleModalRole(state) {
      state.modalRole.currentData = null;
      state.modalRole.isModalShow = !state.modalRole.isModalShow;
    },

    setEditDataModalOtherMenu(state, data) {
      state.modalOtherMenu.currentData = data;
    },

    toggleModalOtherMenu(state) {
      state.modalOtherMenu.currentData = null;
      state.modalOtherMenu.isModalShow = !state.modalOtherMenu.isModalShow;
    },
  },
  actions: {
    getRoles({ commit }) {
      return RoleService.getRoles()
        .then((response) => {
          commit("getRoles", response.data.data);
          return response;
        })
        .catch((error) => {
          console.error(error);
          return error;
        });
    },

    createRole({}, payload) {
      return RoleService.createRole(payload).catch((error) => console.log(error));
    },

    getDetailRole({ commit }, payload) {
      return RoleService.getDetailRole(payload)
        .then((response) => {
          commit("toggleModalRole");
          commit("setEditDataModalRole", response.data.data);
          return response;
        })
        .catch((error) => {
          console.error(error);
          return error;
        });
    },

    updateRole({}, payload) {
      return RoleService.updateRole(payload).catch((error) => {
        console.error(error);
        return error;
      });
    },

    deleteRole({}, payload) {
      return RoleService.deleteRole(payload).catch((error) => {
        console.error(error);
        return error;
      });
    },

    setEditDataModalRole({ commit }, payload) {
      commit("toggleModalRole");
      commit("setEditDataModalRole", payload);
    },

    toggleModalRole({ commit }) {
      return commit("toggleModalRole");
    },

    getMenu({ commit }) {
      return RoleService.getMenu()
        .then((response) => {
          commit("getMenu", response.data.data);
          return response;
        })
        .catch((error) => {
          console.error(error);
          return error;
        });
    },

    setEditDataModalOtherMenu({ commit }, payload) {
      commit("toggleModalOtherMenu");
      commit("setEditDataModalOtherMenu", payload);
    },

    toggleModalOtherMenu({ commit }) {
      return commit("toggleModalOtherMenu");
    },
  },
};

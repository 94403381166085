import PartnerService from "@/services/partnerService";

export default {
  namespaced: true,
  state: { categoryList: [], partner: [] },
  getters: {
    getCategoryList: (state) => state.categoryList,
  },
  mutations: {
    getCategoryList(state, data) {
      state.categoryList = data;
    },
  },
  actions: {
    getCategoryList({ commit }, payload) {
      return PartnerService.getCategoryList()
        .then((response) => {
          commit("getCategoryList", response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createPartner({ commit }, payload) {
      return PartnerService.createPartner(payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    updatePartner({ commit }, payload) {
      return;
    },
    deletePartner({ commit }, payload) {
      return;
    },
  },
};

import apiClient from "@/services/api";
import tokenService from "@/services/tokenService";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    summary: {
      chart: {
        loading: false,
        data: {
          categories: [],
          series: [],
        },
        error: "",
      },
      jumlah: {
        loading: false,
        data: {
          active: {
            number: 0,
            from_last_month: 0,
          },
          category: {
            number: 0,
            from_last_month: 0,
          },
        },
        error: "",
      },
      top: {
        category: {
          loading: false,
          data: [],
          error: "",
        },
        transaction: {
          loading: false,
          data: [],
          error: "",
        },
      },
    },
    menunggu_persetujuan: {
      loading: false,
      firts_load: true,
      last_record: false,
      filter: {
        limit: 10,
        search: "",
        category_id: "",
      },
      list: [],
      meta: {},
      detail: {
        loading: false,
        data: null,
      },
      error: "",
    },
    aktif: {
      loading: false,
      firts_load: true,
      last_record: false,
      filter: {
        limit: 10,
        search: "",
        category_id: "",
      },
      list: [],
      meta: {},
      detail: {
        loading: false,
        data: null,
        summary: {
          loading: false,
          data: null,
          error: "",
        },
        chart: {
          loading: false,
          data: {},
          error: "",
        },
      },
      error: "",
    },
    tipemerchant: {
      loading: false,
      data: [],
      error: "",
    },
    menunggu_respon_selanjutnya: {
      loading: false,
      firts_load: true,
      last_record: false,
      filter: {
        limit: 10,
        search: "",
        category_id: "",
      },
      list: [],
      meta: {},
      detail: {
        loading: false,
        data: null,
      },
      error: "",
    },
    status: {
      approved: {
        loading: false,
        data: null,
        message: "",
        error: "",
      },
      reject: {
        loading: false,
        data: null,
        message: "",
        error: "",
      },
      sendback: {
        loading: false,
        data: null,
        message: "",
        error: "",
      },
    },
    booking: {
      loading: false,
      firts_load: true,
      last_record: false,
      filter: {
        limit: 10,
        search: "",
      },
      list: [],
      meta: {},
      detail: {
        loading: false,
        data: null,
      },
      error: "",
    },
  },
  mutations: {
    // tipe merchant
    setTipeMerchant(state, payload) {
      state.tipemerchant.loading = payload.loading;
      state.tipemerchant.data = payload.data;
      if (payload.error != undefined) {
        state.tipemerchant.error = payload.error;
      }
    },

    // Merchant Summary
    setChart(state, payload) {
      state.summary.chart.loading = payload.loading;
      state.summary.chart.data = payload.data;
      if (payload.error != undefined) {
        state.summary.chart.error = payload.error;
      }
    },
    setJumlah(state, payload) {
      state.summary.jumlah.loading = payload.loading;
      state.summary.jumlah.data = payload.data;
      if (payload.error != undefined) {
        state.summary.jumlah.error = payload.error;
      }
    },
    setTopCategory(state, payload) {
      state.summary.top.category.loading = payload.loading;
      state.summary.top.category.data = payload.data;
      if (payload.error != undefined) {
        state.summary.top.category.error = payload.error;
      }
    },
    setTopTransaction(state, payload) {
      state.summary.top.transaction.loading = payload.loading;
      state.summary.top.transaction.data = payload.data;
      if (payload.error != undefined) {
        state.summary.top.transaction.error = payload.error;
      }
    },
    // Merchant menunggu persetujuan
    setListMenungguPersetujuan(state, payload) {
      state.menunggu_persetujuan.loading = payload.loading;
      state.menunggu_persetujuan.last_record = payload.last_record;
      if (payload.list != undefined) {
        if (payload.firts_load) {
          state.menunggu_persetujuan.list = payload.list;
        } else {
          state.menunggu_persetujuan.list.push(...payload.list);
        }
      }
      state.menunggu_persetujuan.meta = payload.meta;
      if (payload.error != undefined) {
        state.menunggu_persetujuan.error = payload.error;
      }
    },
    setDetailMenungguPersetujuan(state, payload) {
      state.menunggu_persetujuan.detail.loading = payload.loading;
      state.menunggu_persetujuan.detail.data = payload.data;
      if (payload.error != undefined) {
        state.menunggu_persetujuan.error = payload.error;
      }
    },

    // menunggu respon selanjutnya
    setListMenungguRespon(state, payload) {
      state.menunggu_respon_selanjutnya.loading = payload.loading;
      state.menunggu_respon_selanjutnya.last_record = payload.last_record;
      if (payload.list != undefined) {
        if (payload.firts_load) {
          state.menunggu_respon_selanjutnya.list = payload.list;
        } else {
          state.menunggu_respon_selanjutnya.list.push(...payload.list);
        }
      }
      state.menunggu_respon_selanjutnya.meta = payload.meta;
      if (payload.error != undefined) {
        state.menunggu_respon_selanjutnya.error = payload.error;
      }
    },
    setDetailMenungguRespon(state, payload) {
      state.menunggu_respon_selanjutnya.detail.loading = payload.loading;
      state.menunggu_respon_selanjutnya.detail.data = payload.data;
      if (payload.error != undefined) {
        state.menunggu_respon_selanjutnya.error = payload.error;
      }
    },

    // Merchant Aktif
    setListAktif(state, payload) {
      state.aktif.loading = payload.loading;
      state.aktif.last_record = payload.last_record;
      if (payload.list != undefined) {
        if (payload.firts_load) {
          state.aktif.list = payload.list;
        } else {
          state.aktif.list.push(...payload.list);
        }
      }
      state.aktif.meta = payload.meta;
      if (payload.error != undefined) {
        state.aktif.error = payload.error;
      }
    },
    setDetailAktif(state, payload) {
      state.aktif.detail.loading = payload.loading;
      state.aktif.detail.data = payload.data;
      if (payload.error != undefined) {
        state.aktif.error = payload.error;
      }
    },
    setDetailAktifSummary(state, payload) {
      state.aktif.detail.summary.loading = payload.loading;
      state.aktif.detail.summary.data = payload.data;
      if (payload.error != undefined) {
        state.aktif.detail.summary.error = payload.error;
      }
    },
    setDetailAktifChart(state, payload) {
      state.aktif.detail.chart.loading = payload.loading;
      state.aktif.detail.chart.data = payload.data;
      if (payload.error != undefined) {
        state.aktif.error = payload.error;
      }
    },

    // status approved / reject
    setApproved(state, payload) {
      state.status.approved.loading = payload.loading;
      state.status.approved.data = payload.data;
      state.status.approved.message = payload.message;
      if (payload.error != undefined) {
        state.status.approved.error = payload.error;
      }
    },
    setReject(state, payload) {
      state.status.reject.loading = payload.loading;
      state.status.reject.data = payload.data;
      state.status.reject.message = payload.message;
      if (payload.error != undefined) {
        state.status.reject.error = payload.error;
      }
    },
    setSendBack(state, payload) {
      state.status.sendback.loading = payload.loading;
      state.status.sendback.data = payload.data;
      state.status.sendback.message = payload.message;
      if (payload.error != undefined) {
        state.status.sendback.error = payload.error;
      }
    },
    // Merchant list event
    setListBooking(state, payload) {
      state.booking.loading = payload.loading;
      state.booking.last_record = payload.last_record;
      if (payload.list != undefined) {
        if (payload.firts_load) {
          state.booking.list = payload.list;
        } else {
          state.booking.list.push(...payload.list);
        }
      }
      state.booking.meta = payload.meta;
      if (payload.error != undefined) {
        state.booking.error = payload.error;
      }
    },
  },
  actions: {
    // tipe merchant
    _getTipeMerchant({ commit }, params) {
      var term =
        params != undefined && params.term != undefined ? params.term : "";
      var limit =
        params != undefined && params.limit != undefined ? params.limit : 50;
      var cursor =
        params != undefined && params.cursor != undefined ? params.cursor : "";

      commit("setTipeMerchant", {
        loading: true,
        data: [],
        error: "",
      });
      apiClient
        .get(
          `authority/data/list/category?term=${term}&limit=${limit}&cursor=${cursor}`,
          {
            headers: {
              Authorization: `Bearer ${
                tokenService.getUserToken().access_token
              }`,
            },
          }
        )
        .then((res) => {
          commit("setTipeMerchant", {
            loading: false,
            data: res.data.data,
          });
        })
        .catch(({ message, response }) => {
          var err500 = "";
          if (response != undefined && response.status === 500) {
            err500 = " ," + response.data.message;
          }
          commit("setTipeMerchant", {
            loading: false,
            error: message + err500,
          });
        });
    },
    // Merchant Summary
    _getChart({ commit }, params) {
      // var id = '958a5b74-eb18-4ce7-8c0d-1b7aa4fd8bb4'
      var id = params != undefined && params.id != undefined ? params.id : "";
      // filter_type : total_meetup, ongoing_meetup
      var filter_type =
        params != undefined && params.filter_type != undefined
          ? params.filter_type
          : "";
      // date_type : month, year
      var date_type =
        params != undefined && params.date_type != undefined
          ? params.date_type
          : "";

      commit("setChart", {
        loading: true,
        data: {
          categories: [],
          series: [],
        },
        error: "",
      });
      apiClient
        .get(
          `authority/merchant/summary/chart?id=${id}&filter_type=${filter_type}&date_type=${date_type}`,
          {
            headers: {
              Authorization: `Bearer ${
                tokenService.getUserToken().access_token
              }`,
            },
          }
        )
        .then((res) => {
          let data = res.data.data;
          let categories_before = [];
          let series_before = [];
          let categories_current = [];
          let series_current = [];
          let categories = [];

          if (data.before?.length > 0) {
            for (let index = 0; index < data.before.length; index++) {
              categories_before.push(
                moment(data.before[index].date).format("l")
              );
              series_before.push(data.before[index].count);
            }
          } else {
            if (data.current?.length > 0) {
              for (let index = 0; index < data.current?.length; index++) {
                series_before.push(0);
              }
            } else {
              series_before.push(0);
            }
          }

          if (data.current?.length > 0) {
            for (let index = 0; index < data.current.length; index++) {
              categories_current.push(
                moment(data.current[index].date).format("l")
              );
              series_current.push(data.current[index].count);
            }
          } else {
            if (data.before?.length > 0) {
              for (let index = 0; index < data.before?.length; index++) {
                series_current.push(0);
              }
            } else {
              series_current.push(0);
            }
          }
          categories = categories_before.concat(
            categories_current.filter(
              (item) => categories_before.indexOf(item) < 0
            )
          );
          let payload = {
            categories: categories,
            series: [
              {
                name: "Bulan Lalu",
                data: series_before,
              },
              {
                name: "Bulan Ini",
                data: series_current,
              },
            ],
          };

          // console.log(payload)
          commit("setChart", {
            loading: false,
            data: payload,
            error: "",
          });
        })
        .catch(({ message, response }) => {
          var err500 = "";
          if (response != undefined && response.status === 500) {
            err500 = " ," + response.data.message;
          }
          commit("setChart", {
            loading: false,
            data: {
              categories: [],
              series: [],
            },
            error: message + err500,
          });
        });
    },
    _getJumlah({ commit }) {
      commit("setJumlah", {
        loading: true,
        data: {
          active: {
            number: 0,
            from_last_month: 0,
          },
          category: {
            number: 0,
            from_last_month: 0,
          },
        },
        error: "",
      });
      apiClient
        .get(`authority/merchant/summary/number`, {
          headers: {
            Authorization: `Bearer ${tokenService.getUserToken().access_token}`,
          },
        })
        .then((res) => {
          commit("setJumlah", {
            loading: false,
            data: res.data.data,
          });
        })
        .catch(({ message, response }) => {
          var err500 = "";
          if (response != undefined && response.status === 500) {
            err500 = " ," + response.data.message;
          }
          commit("setJumlah", {
            loading: false,
            error: message + err500,
          });
        });
    },
    _getTopCategory({ commit }) {
      commit("setTopCategory", {
        loading: true,
        data: [],
        error: "",
      });
      apiClient
        .get(`authority/merchant/summary/top_category`, {
          headers: {
            Authorization: `Bearer ${tokenService.getUserToken().access_token}`,
          },
        })
        .then((res) => {
          commit("setTopCategory", {
            loading: false,
            data: res.data.data,
          });
        })
        .catch(({ message, response }) => {
          var err500 = "";
          if (response != undefined && response.status === 500) {
            err500 = " ," + response.data.message;
          }
          commit("setTopCategory", {
            loading: false,
            error: message + err500,
          });
        });
    },
    _getTopTransaction({ commit }) {
      commit("setTopTransaction", {
        loading: true,
        data: [],
        error: "",
      });
      apiClient
        .get(`authority/merchant/summary/top_transaction`, {
          headers: {
            Authorization: `Bearer ${tokenService.getUserToken().access_token}`,
          },
        })
        .then((res) => {
          commit("setTopTransaction", {
            loading: false,
            data: res.data.data,
          });
        })
        .catch(({ message, response }) => {
          var err500 = "";
          if (response != undefined && response.status === 500) {
            err500 = " ," + response.data.message;
          }
          commit("setTopTransaction", {
            loading: false,
            error: message + err500,
          });
        });
    },
    // Merchant menunggu persetujuan
    _getListMenungguPersetujuan({ commit }, params) {
      var search =
        params != undefined && params.search != undefined ? params.search : "";
      var category_id =
        params != undefined && params.category_id != undefined
          ? params.category_id
          : "";
      var limit =
        params != undefined && params.limit != undefined ? params.limit : 10;
      var cursor =
        params != undefined && params.meta != undefined ? params.meta : "";
      var firts_load =
        params != undefined && params.firts_load != undefined
          ? params.firts_load
          : true;

      commit("setListMenungguPersetujuan", {
        loading: true,
        firts_load: firts_load,
        list: [],
        meta: {},
        error: "",
      });

      cursor = cursor.next_cursor != undefined ? cursor.next_cursor : "";
      apiClient
        .get(
          `authority/merchant/submission/list?limit=${limit}&cursor=${cursor}&search=${search}&category_id=${category_id}`,
          {
            headers: {
              Authorization: `Bearer ${
                tokenService.getUserToken().access_token
              }`,
            },
          }
        )
        .then((res) => {
          var last_record = false;
          if (res.data.meta.pagination.next_cursor === null) {
            last_record = true;
          }

          commit("setListMenungguPersetujuan", {
            loading: false,
            list: res.data.data,
            meta: res.data.meta,
            firts_load: firts_load,
            last_record: last_record,
          });
        })
        .catch(({ message, response }) => {
          // console.log('catch response :', response)
          var err500 = "";
          if (response != undefined && response.status === 500) {
            err500 = " ," + response.data.message;
          }
          commit("setListMenungguPersetujuan", {
            loading: false,
            firts_load: firts_load,
            error: message + err500,
          });
        });
    },
    _getDetailMenungguPersetujuan({ commit }, id) {
      // var id = params != undefined && params.id != undefined ? params.id : ''

      commit("setDetailMenungguPersetujuan", {
        loading: true,
        error: "",
      });
      apiClient
        .get(`authority/merchant/submission/detail?id=${id}`, {
          headers: {
            Authorization: `Bearer ${tokenService.getUserToken().access_token}`,
          },
        })
        .then((res) => {
          commit("setDetailMenungguPersetujuan", {
            loading: false,
            data: res.data.data,
          });
        })
        .catch(({ message, response }) => {
          var err500 = "";
          if (response != undefined && response.status === 500) {
            err500 = " ," + response.data.message;
          }
          commit("setDetailMenungguPersetujuan", {
            loading: false,
            error: message + err500,
          });
        });
    },
    // menunggu respon selanjutnya
    _getListMenungguRespon({ commit }, params) {
      var search =
        params != undefined && params.search != undefined ? params.search : "";
      var category_id =
        params != undefined && params.category_id != undefined
          ? params.category_id
          : "";
      var limit =
        params != undefined && params.limit != undefined ? params.limit : 10;
      var cursor =
        params != undefined && params.meta != undefined ? params.meta : "";
      var firts_load =
        params != undefined && params.firts_load != undefined
          ? params.firts_load
          : true;

      commit("setListMenungguRespon", {
        loading: true,
        firts_load: firts_load,
        list: [],
        meta: {},
        error: "",
      });

      cursor = cursor.next_cursor != undefined ? cursor.next_cursor : "";
      apiClient
        .get(
          `authority/merchant/rejected/list?limit=${limit}&cursor=${cursor}&search=${search}&category_id=${category_id}`,
          {
            headers: {
              Authorization: `Bearer ${
                tokenService.getUserToken().access_token
              }`,
            },
          }
        )
        .then((res) => {
          var last_record = false;
          if (res.data.meta.pagination.next_cursor === null) {
            last_record = true;
          }

          commit("setListMenungguRespon", {
            loading: false,
            list: res.data.data,
            meta: res.data.meta,
            firts_load: firts_load,
            last_record: last_record,
          });
        })
        .catch(({ message, response }) => {
          // console.log('catch response :', response)
          var err500 = "";
          if (response != undefined && response.status === 500) {
            err500 = " ," + response.data.message;
          }
          commit("setListMenungguRespon", {
            loading: false,
            firts_load: firts_load,
            error: message + err500,
          });
        });
    },
    _getDetailMenungguRespon({ commit }, id) {
      // var id = params != undefined && params.id != undefined ? params.id : ''

      commit("setDetailMenungguRespon", {
        loading: true,
        error: "",
      });
      apiClient
        .get(`authority/merchant/rejected/detail?id=${id}`, {
          headers: {
            Authorization: `Bearer ${tokenService.getUserToken().access_token}`,
          },
        })
        .then((res) => {
          commit("setDetailMenungguRespon", {
            loading: false,
            data: res.data.data,
          });
        })
        .catch(({ message, response }) => {
          var err500 = "";
          if (response != undefined && response.status === 500) {
            err500 = " ," + response.data.message;
          }
          commit("setDetailMenungguRespon", {
            loading: false,
            error: message + err500,
          });
        });
    },

    // Merchant Aktif
    _getListAktif({ commit }, params) {
      var search =
        params != undefined && params.search != undefined ? params.search : "";
      var category_id =
        params != undefined && params.category_id != undefined
          ? params.category_id
          : "";
      var limit =
        params != undefined && params.limit != undefined ? params.limit : 10;
      var cursor =
        params != undefined && params.meta != undefined ? params.meta : "";
      var firts_load =
        params != undefined && params.firts_load != undefined
          ? params.firts_load
          : true;

      commit("setListAktif", {
        loading: true,
        firts_load: firts_load,
        list: [],
        meta: {},
        error: "",
      });

      cursor = cursor.next_cursor != undefined ? cursor.next_cursor : "";
      apiClient
        .get(
          `authority/merchant/active/list?limit=${limit}&cursor=${cursor}&search=${search}&category_id=${category_id}`,
          {
            headers: {
              Authorization: `Bearer ${
                tokenService.getUserToken().access_token
              }`,
            },
          }
        )
        .then((res) => {
          // console.log(res)
          var last_record = false;
          if (res.data.meta.pagination.next_cursor === null) {
            last_record = true;
          }

          commit("setListAktif", {
            loading: false,
            list: res.data.data,
            meta: res.data.meta,
            firts_load: firts_load,
            last_record: last_record,
          });
        })
        .catch(({ message, response }) => {
          // console.log('catch response :', response)
          var err500 = "";
          if (response != undefined && response.status === 500) {
            err500 = " ," + response.data.message;
          }
          commit("setListAktif", {
            loading: false,
            firts_load: firts_load,
            error: message + err500,
          });
        });
    },
    _getDetailAktif({ commit }, id) {
      // var id = params != undefined && params.id != undefined ? params.id : ''

      commit("setDetailAktif", {
        loading: true,
        error: "",
      });
      apiClient
        .get(`authority/merchant/active/detail?id=${id}`, {
          headers: {
            Authorization: `Bearer ${tokenService.getUserToken().access_token}`,
          },
        })
        .then((res) => {
          commit("setDetailAktif", {
            loading: false,
            data: res.data.data,
          });
        })
        .catch(({ message, response }) => {
          var err500 = "";
          if (response != undefined && response.status === 500) {
            err500 = " ," + response.data.message;
          }
          commit("setDetailAktif", {
            loading: false,
            error: message + err500,
          });
        });
    },
    _getDetailAktifSummary({ commit }, id) {
      commit("setDetailAktifSummary", {
        loading: true,
        data: null,
        error: "",
      });
      apiClient
        .get(`authority/merchant/active/number?id=${id}`, {
          headers: {
            Authorization: `Bearer ${tokenService.getUserToken().access_token}`,
          },
        })
        .then((res) => {
          commit("setDetailAktifSummary", {
            loading: false,
            data: res.data.data,
          });
        })
        .catch(({ message, response }) => {
          var err500 = "";
          if (response != undefined && response.status === 500) {
            err500 = " ," + response.data.message;
          }
          commit("setDetailAktifSummary", {
            loading: false,
            data: null,
            error: message + err500,
          });
        });
    },
    _getDetailAktifChart({ commit }, params) {
      var id = params != undefined && params.id != undefined ? params.id : "";
      var filter_type =
        params != undefined && params.filter_type != undefined
          ? params.filter_type
          : "profit";
      var date_type =
        params != undefined && params.date_type != undefined
          ? params.date_type
          : "month";

      commit("setDetailAktifChart", {
        loading: true,
        error: "",
      });
      apiClient
        .get(
          `authority/merchant/active/chart?id=${id}&filter_type=${filter_type}&date_type=${date_type}`,
          {
            headers: {
              Authorization: `Bearer ${
                tokenService.getUserToken().access_token
              }`,
            },
          }
        )
        .then((res) => {
          let data = res.data.data;
          let categories_before = [];
          let series_before = [];
          let categories_current = [];
          let series_current = [];
          let categories = [];

          if (data.before?.length > 0) {
            for (let index = 0; index < data.before.length; index++) {
              categories_before.push(
                moment(data.before[index].date).format("l")
              );
              series_before.push(data.before[index].count);
            }
          } else {
            if (data.current?.length > 0) {
              for (let index = 0; index < data.current?.length; index++) {
                series_before.push(0);
              }
            } else {
              series_before.push(0);
            }
          }

          if (data.current?.length > 0) {
            for (let index = 0; index < data.current.length; index++) {
              categories_current.push(
                moment(data.current[index].date).format("l")
              );
              series_current.push(data.current[index].count);
            }
          } else {
            if (data.before?.length > 0) {
              for (let index = 0; index < data.before?.length; index++) {
                series_current.push(0);
              }
            } else {
              series_current.push(0);
            }
          }
          categories = categories_before.concat(
            categories_current.filter(
              (item) => categories_before.indexOf(item) < 0
            )
          );
          let payload = {
            categories: categories,
            series: [
              {
                name: "Bulan Lalu",
                data: series_before,
              },
              {
                name: "Bulan Ini",
                data: series_current,
              },
            ],
          };
          commit("setDetailAktifChart", {
            loading: false,
            data: payload,
          });
        })
        .catch(({ message, response }) => {
          var err500 = "";
          if (response != undefined && response.status === 500) {
            err500 = " ," + response.data.message;
          }
          commit("setDetailAktifChart", {
            loading: false,
            error: message + err500,
          });
        });
    },
    _setApproved({ commit }, id) {
      commit("setApproved", {
        loading: true,
        data: null,
        message: "",
        error: "",
      });

      var appFormdata = new FormData();
      appFormdata.append("id", id);
      apiClient
        .post(`authority/merchant/submission/approve`, appFormdata, {
          headers: {
            Authorization: `Bearer ${tokenService.getUserToken().access_token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          commit("setApproved", {
            loading: false,
            data: res.data.data,
            message: res.data.message,
          });
        })
        .catch(({ message, response }) => {
          var err500 = "";
          if (response != undefined && response.status === 500) {
            err500 = " ," + response.data.message;
          }
          commit("setApproved", {
            loading: false,
            message: "",
            data: null,
            error: message + err500,
          });
        });
    },
    _setPending({ commit }, id) {
      commit("setPending", {
        loading: true,
        data: null,
        message: "",
        error: "",
      });

      var appFormdata = new FormData();
      appFormdata.append("id", id);
      apiClient
        .post(`authority/merchant/active/pending`, appFormdata, {
          headers: {
            Authorization: `Bearer ${tokenService.getUserToken().access_token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          commit("setPending", {
            loading: false,
            data: res.data.data,
            message: res.data.message,
          });
        })
        .catch(({ message, response }) => {
          var err500 = "";
          if (response != undefined && response.status === 500) {
            err500 = " ," + response.data.message;
          }
          commit("setPending", {
            loading: false,
            message: "",
            data: null,
            error: message + err500,
          });
        });
    },
    _setReject({ commit }, params) {
      var id = params != undefined && params.id != undefined ? params.id : "";
      var reason =
        params != undefined && params.reason != undefined ? params.reason : "";
      commit("setReject", {
        loading: true,
        data: null,
        message: "",
        error: "",
      });
      var appFormdata = new FormData();
      appFormdata.append("id", id);
      appFormdata.append("reason", reason);

      apiClient
        .post(`authority/merchant/submission/reject`, appFormdata, {
          headers: {
            Authorization: `Bearer ${tokenService.getUserToken().access_token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          commit("setReject", {
            loading: false,
            data: res.data.data,
            message: res.data.message,
          });
        })
        .catch(({ message, response }) => {
          var err500 = "";
          if (response != undefined && response.status === 500) {
            err500 = " ," + response.data.message;
          }
          commit("setReject", {
            loading: false,
            message: "",
            error: message + err500,
          });
        });
    },
    _setSendBack({ commit }, params) {
      var id = params != undefined && params.id != undefined ? params.id : "";
      var complete_information =
        params != undefined && params.complete_information != undefined
          ? params.complete_information
          : "";
      var complete_document =
        params != undefined && params.complete_document != undefined
          ? params.complete_document
          : "";
      var clear_document =
        params != undefined && params.clear_document != undefined
          ? params.clear_document
          : "";
      var contacted =
        params != undefined && params.contacted != undefined
          ? params.contacted
          : "";
      var other =
        params != undefined && params.other != undefined ? params.other : "";

      commit("setSendBack", {
        loading: true,
        data: null,
        message: "",
        error: "",
      });
      var bodyFormData = new FormData();
      bodyFormData.append("id", id);
      bodyFormData.append("complete_information", complete_information);
      bodyFormData.append("complete_document", complete_document);
      bodyFormData.append("clear_document", clear_document);
      bodyFormData.append("contacted", contacted);
      bodyFormData.append("other", other);

      apiClient
        .post(`authority/merchant/submission/send_back`, bodyFormData, {
          headers: {
            Authorization: `Bearer ${tokenService.getUserToken().access_token}`,
            "Content-Type": "multipart/form-data",
          },
          // data: bodyFormData
        })
        .then((res) => {
          commit("setSendBack", {
            loading: false,
            data: res.data.data,
            message: res.data.message,
          });
        })
        .catch(({ message, response }) => {
          var err500 = "";
          if (response != undefined && response.status === 500) {
            err500 = " ," + response.data.message;
          }
          commit("setSendBack", {
            loading: false,
            message: "",
            error: message + err500,
          });
        });
    },
    // Merchant list event
    _getListBooking({ commit }, params) {
      var id = params != undefined && params.id != undefined ? params.id : "";
      var search =
        params != undefined && params.search != undefined ? params.search : "";
      var limit =
        params != undefined && params.limit != undefined ? params.limit : 10;
      var cursor =
        params != undefined && params.meta != undefined ? params.meta : "";
      var firts_load =
        params != undefined && params.firts_load != undefined
          ? params.firts_load
          : true;

      commit("setListBooking", {
        loading: true,
        firts_load: firts_load,
        list: [],
        meta: {},
        error: "",
      });

      cursor = cursor.next_cursor != undefined ? cursor.next_cursor : "";
      // apiClient.get(`authority/merchant/active/list?limit=${limit}&cursor=${cursor}&search=${search}&category_id=${category_id}`, {
      apiClient
        .get(
          `authority/merchant/happening/booking_list?happening_id=${id}&limit=${limit}&cursor=${cursor}`,
          {
            headers: {
              Authorization: `Bearer ${
                tokenService.getUserToken().access_token
              }`,
            },
          }
        )
        .then((res) => {
          // console.log(res)
          var last_record = false;
          if (res.data.meta.pagination.next_cursor === null) {
            last_record = true;
          }

          commit("setListBooking", {
            loading: false,
            list: res.data.data,
            meta: res.data.meta,
            firts_load: firts_load,
            last_record: last_record,
          });
        })
        .catch(({ message, response }) => {
          // console.log('catch response :', response)
          var err500 = "";
          if (response != undefined && response.status === 500) {
            err500 = " ," + response.data.message;
          }
          commit("setListBooking", {
            loading: false,
            firts_load: firts_load,
            error: message + err500,
          });
        });
    },
  },
  getters: {
    // Tipe Merchant
    tipemerchant(state) {
      return state.tipemerchant;
    },
    // Merchant Summary
    summry_chart(state) {
      return state.summary.chart;
    },
    summry_jumlah(state) {
      return state.summary.jumlah;
    },
    summary_top_category(state) {
      return state.summary.top.category;
    },
    summary_top_transaction(state) {
      return state.summary.top.transaction;
    },
    // Merchant menunggu persetujuan
    filter_menunggu_persetujuan(state) {
      return state.menunggu_persetujuan.filter;
    },
    loading_list_menunggu_persetujuan(state) {
      return state.menunggu_persetujuan.loading;
    },
    list_menunggu_persetujuan(state) {
      return state.menunggu_persetujuan.list;
    },
    meta_menunggu_persetujuan(state) {
      return state.menunggu_persetujuan.meta;
    },
    detail_menunggu_persetujuan_loading(state) {
      return state.menunggu_persetujuan.detail.loading;
    },
    detail_menunggu_persetujuan_data(state) {
      return state.menunggu_persetujuan.detail.data;
    },
    last_recored_list_menunggu_persetujuan(state) {
      return state.menunggu_persetujuan.last_record;
    },
    error_menunggu_persetujuan(state) {
      return state.menunggu_persetujuan.error;
    },

    // Merchant Aktif
    filter_aktif(state) {
      return state.aktif.filter;
    },
    loading_list_aktif(state) {
      return state.aktif.loading;
    },
    list_aktif(state) {
      return state.aktif.list;
    },
    meta_aktif(state) {
      return state.aktif.meta;
    },
    detail_aktif_loading(state) {
      return state.aktif.detail.loading;
    },
    detail_aktif_data(state) {
      return state.aktif.detail.data;
    },
    // detail_aktif_summary_loading(state) {
    //     return state.aktif.detail.summary.loading
    // },
    detail_aktif_summary(state) {
      return state.aktif.detail.summary;
    },
    detail_aktif_chart_loading(state) {
      return state.aktif.detail.chart.loading;
    },
    detail_aktif_chart_data(state) {
      return state.aktif.detail.chart.data;
    },
    last_recored_list_aktif(state) {
      return state.aktif.last_record;
    },
    error_aktif(state) {
      return state.aktif.error;
    },

    // status approved / reject
    status_approved(state) {
      return state.status.approved;
    },
    status_reject(state) {
      return state.status.reject;
    },
    status_sendback(state) {
      return state.status.sendback;
    },

    menunggu_respon_selanjutnya(state) {
      return state.menunggu_respon_selanjutnya;
    },
    detail_menunggu_respon_selanjutnya(state) {
      return state.menunggu_respon_selanjutnya.detail;
    },
    // booking
    event_booking(state) {
      return state.booking;
    },
  },
};

<template>
  <div class="min-h-screen min-w-full flex md:flex-col lg:flex-row">
    <div class="wrapper-left m-6 rounded-2xl">
      <div class="lg:flex-1 m-10">
        <img src="@/assets/mobooking-logo-blue.png" width="250" alt="Logo" />
        <p class="text-body1 text-medium-grey-80 mt-4">
          Make your life more easier <br />
          with us
        </p>
      </div>
      <img
        src="@/assets/illustrations/il-login.png"
        height="685"
        alt="Illustration-Login"
      />
    </div>
    <!--tes-->
    <div class="lg:flex-1 flex flex-col justify-center items-center">
      <div class="wrapper-right">
        <div class="absolute top-10 right-10">
          <!--
            <router-link
            tag="a"
            active-class=""
            class="
              inline-block
              px-5
              py-2
              bg-medium-blue
              hover:bg-blue
              text-white text-button1
              font-bold
              rounded-full
            "
            to="/form-pengajuan-merchant"
          >
            <img
              class="float-left mt-1 mr-2"
              src="@/assets/icons/ic-person-white.svg"
              alt="Icon Join"
            />
            Join Us
          </router-link>
          -->
        </div>
        <h1 class="font-bold text-dark-grey-80 text-heading3">
          👋 <br />
          Halo, <br />
          Selamat Datang!
        </h1>
        <h2 class="text-body2 pt-4 pb-10 text-medium-grey-80">
          Silakan mengisi username dan password di bawah
        </h2>

        <div
          v-if="showErrorMessage !== ''"
          class="
            flex
            items-center
            justify-between
            bg-dark-red bg-opacity-40
            rounded-lg
            border-1 border-dark-red
            p-4
            mb-4
          "
          role="alert"
        >
          <span class="text-body4 text-dark-grey-80">
            {{ showErrorMessage }}
          </span>
          <img
            @click="toggleError"
            src="@/assets/icons/ic-close.svg"
            class="w-4 h-4 hover:cursor-pointer"
            alt="close"
          />
        </div>

        <form @submit.prevent="handleLogin" @keyup.enter="handleLogin">
          <fieldset>
            <legend class="font-bold text-dark-grey-80 text-subtitle3">
              Username
            </legend>
            <input
              class="
                text-body3 text-dark-grey-80
                w-full
                py-3
                border-b-2 border-light-grey-cc
                focus:outline-none focus:border-medium-blue
              "
              :class="errors.username !== null && 'focus:border-dark-red'"
              type="text"
              v-model="username"
              placeholder="Tulis username di sini"
              required
            />
            <span
              class="flex mt-2 text-dark-red text-body4"
              v-if="errors.username"
              >{{ errors.username }}</span
            >
          </fieldset>

          <fieldset class="mt-6 relative">
            <legend class="font-bold text-dark-grey-80 text-subtitle3">
              Password
            </legend>

            <img
              @click="handleShow"
              src="@/assets/icons/ic-eye.svg"
              class="
                cursor-pointer
                w-5
                h-5
                absolute
                z-10
                top-1/2
                transform
                -translate-y-1/2
                right-3
              "
              alt="icon-password "
            />

            <input
              class="
                text-body3 text-dark-grey-80
                w-full
                py-3
                z-0
                border-b-2 border-light-grey-cc
                focus:outline-none focus:border-medium-blue
              "
              :class="errors.password && 'focus:border-dark-red'"
              :type="isPasswordShow ? 'text' : 'password'"
              v-model="password"
              placeholder="Tulis password di sini"
              required
            />
            <span
              class="flex mt-2 text-dark-red text-body4"
              v-if="errors.password"
              >{{ errors.password }}</span
            >
          </fieldset>

          <div class="flex justify-between py-10">
            <div class="flex">
              <label class="inline-flex items-center">
                <input
                  type="checkbox"
                  class="form-checkbox h-5 w-5"
                  checked
                /><span
                  class="ml-3 text-dark-grey-80 font-semibold text-subtitle3"
                  >Ingatkan Saya</span
                >
              </label>
            </div>

            <router-link to="/" class="font-semibold text-subtitle3 text-blue"
              >Lupa Password?</router-link
            >
          </div>

          <button
            type="submit"
            :disabled="isLoading"
            class="
              w-full
              bg-medium-blue
              hover:bg-blue
              text-white text-button1
              font-bold
              py-3
              rounded-full
            "
          >
            {{isLoading ? 'Mohon tunggu...':'Masuk'}}
          </button>

          <div class="flex justify-between mt-4">
            <router-link
              tag="a"
              active-class=""
              class="
                bg-baby-blue
                text-center
                hover:bg-blue hover:text-white
                text-medium-blue text-button1
                font-bold
                py-3
                rounded-full
                w-1/2
                mr-2
              "
              to="/privacy-policy"
              >Kebijakan Privasi
            </router-link>
            <router-link
              tag="a"
              active-class=""
              class="
                bg-baby-blue
                text-center
                hover:bg-blue hover:text-white
                text-medium-blue text-button1
                font-bold
                py-3
                rounded-full
                w-1/2
                mr-2
              "
              to="/terms-condition"
              >Syarat Pengguna
            </router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useField, useForm } from "vee-validate";
import { object, string } from "yup";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();

    const isLoading = ref(false);
    const isPasswordShow = ref(false);
    const showErrorMessage = ref("");

    const validationSchema = object({
      username: string()
        .email("Silahkan isi email yang valid")
        .required("Silahkan isi email"),
      password: string()
        .required("Silahkan isi password"),
    });

    const { handleSubmit, errors } = useForm({
      validationSchema,
    });

    const toggleError = () => {
      showErrorMessage.value = "";
    };

    const handleShow = () => {
      isPasswordShow.value = !isPasswordShow.value;
    };

    const handleLogin = handleSubmit((values) => {
      isLoading.value=true
      showErrorMessage.value = ""
      return store
        .dispatch("auth/login", {
          username: values.username,
          password: values.password,
        })
        .then((response) => {
          isLoading.value=false
          if (response.data.status) {
            return store.dispatch("auth/getProfile").then((response) => {
              if (
                response.data.status &&
                response.data.data.role.name == "Lab"
              ) {
                return router.push({ name: "FormTesLab" });
              } else {
                return router.push({ name: "Dashboard" });
              }
            });
          } else {
            return (showErrorMessage.value = response.data.message);
          }
        })
        .catch((error) => {
          isLoading.value=false
          return (showErrorMessage.value = error);
        });
    });

    const { value: username } = useField("username");
    const { value: password } = useField("password");

    return {
      username,
      password,
      handleLogin,
      handleShow,
      isPasswordShow,
      showErrorMessage,
      toggleError,
      errors,
      isLoading
    };
  },
};
</script>

<style scoped>
.wrapper-left {
  background: linear-gradient(180deg, #f6faff 0%, rgba(246, 250, 255, 0) 100%);
}
</style>

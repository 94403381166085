import api from "./api";
import tokenService from "@/services/tokenService";

export default {
  getListAdmin() {
    return api.get("/authority/admin/list");
  },
  getDetailAdmin(id) {
    return api.get(`/authority/admin/detail?id=${id}`);
  },
  createAdmin(payload) {
    return api.post("/authority/admin/create", payload);
  },
  updateAdmin(payload) {
    return api.post("/authority/admin/update", payload);
  },
  deleteAdmin(payload) {
    return api.post("/authority/admin/delete", payload);
  },
  createTestHealth(payload) {
    api.defaults.headers.common["Content-Type"] = "multipart/form-data";
    api.defaults.headers.common["Authorization"] = `Bearer ${tokenService.getUserToken().access_token}`;

    // Timezone Header
    api.defaults.headers.common["X-Timezone"] = "Asia/Jakarta";
    api.defaults.headers.common["X-Localization"] = "id";
    api.defaults.headers.common["Accept"] = "application/json";

    return api.post("/authority/health/test/create", payload);
  },
};

import apiClient from "@/services/api"
import tokenService from "@/services/tokenService"

export default {
    namespaced: true,
    state: {
        list: {
            loading: false,
            data: [],
            meta: {},
            error: '',
        },
        createupdate: {
            loading: false,
            data: null,
            message: '',
            error: '',
        },
        detail: {
            loading: false,
            data: null,
            error: '',
        },
        delbyid: {
            loading: false,
            data: null,
            error: '',
        }
    },
    mutations: {
        setList(state, payload) {
            state.list.loading = payload.loading
            state.list.data = payload.data
            state.list.meta = payload.meta
            if (payload.error != undefined) {
                state.list.error = payload.error
            }
        },
        setCreateUpdate(state, payload) {
            state.createupdate.loading = payload.loading
            state.createupdate.data = payload.data
            if (payload.error != undefined) {
                state.createupdate.error = payload.error
            }
        },
        setDetail(state, payload) {
            state.detail.loading = payload.loading
            state.detail.data = payload.data
            if (payload.error != undefined) {
                state.detail.error = payload.error
            }
        },
        setDelete(state, payload) {
            state.delbyid.loading = payload.loading
            state.delbyid.data = payload.data
            if (payload.error != undefined) {
                state.delbyid.error = payload.error
            }
        },
    },
    actions: {
        _getList({ commit }, params) {
            var search = params != undefined && params.search != undefined ? params.search : ''
            var limit = params != undefined && params.limit != undefined ? params.limit : 10
            var cursor = params != undefined && params.meta != undefined ? params.meta : ''

            commit('setList', {
                loading: true,
                data: [],
                meta: {},
                error: '',
            })

            // cursor = cursor.next_cursor != undefined ? cursor.next_cursor : ''
            apiClient.get(`authority/banner/list?limit=${limit}&cursor=${cursor}&search=${search}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {

                commit('setList', {
                    loading: false,
                    data: res.data.data,
                    meta: res.data.meta,
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setList', {
                    loading: false,
                    data: [],
                    error: message + err500
                })
            })
        },
        _setCreateUpdate({ commit }, params) {

            var id = params != undefined && params.id != undefined ? params.id : ''
            var link = params != undefined && params.link != undefined ? params.link : ''
            var description = params != undefined && params.description != undefined ? params.description : ''
            var status = params != undefined && params.status != undefined ? params.status : ''
            var image = params != undefined && params.image != undefined ? params.image : ''
            var category_id = params != undefined && params.category_id != undefined ? params.category_id : ''


            commit('setCreateUpdate', {
                loading: true,
                data: null,
                message: '',
                error: ''
            })

            var action = 'create'
            var field = new FormData();
            if (id != '') {
                action = 'update'
                field.append('id', id)
            }
            field.append('link', link)
            field.append('description', description)
            field.append('image', image)
            field.append('status', status)
            field.append('category_id', category_id)

            apiClient.post(`authority/banner/${action}`, field, {
                headers: {
                    "Authorization": `Bearer ${tokenService.getUserToken().access_token}`,
                    'Content-Type': 'multipart/form-data'
                },
            }).then((res) => {

                var err = ''
                if (!res.data.status) {
                    err = res.data.message
                }
                commit('setCreateUpdate', {
                    loading: false,
                    data: res.data.data,
                    message: '',
                    error: err
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setCreateUpdate', {
                    loading: false,
                    message: '',
                    data: null,
                    error: message + err500
                })
            })
        },
        _getDetail({ commit }, id) {
            commit('setDetail', {
                loading: true,
                data: null,
                error: '',
            })

            // cursor = cursor.next_cursor != undefined ? cursor.next_cursor : ''
            apiClient.get(`authority/banner/detail?id=${id}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {

                commit('setDetail', {
                    loading: false,
                    data: res.data.data,
                    error: '',
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setDetail', {
                    loading: false,
                    data: null,
                    error: message + err500
                })
            })
        },
        _setDelete({ commit }, id) {

            commit('setDelete', {
                loading: true,
                data: null,
                message: '',
                error: ''
            })

            // var field = new FormData();
            // field.append('id', id)

            apiClient.post(`authority/banner/delete?id=${id}`, {
                headers: {
                    "Authorization": `Bearer ${tokenService.getUserToken().access_token}`,
                    'Content-Type': 'multipart/form-data'
                },
            }).then((res) => {

                var err = ''
                if (!res.data.status) {
                    err = res.data.message
                }
                commit('setDelete', {
                    loading: false,
                    data: res.data.data,
                    message: '',
                    error: err
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setDelete', {
                    loading: false,
                    message: '',
                    data: null,
                    error: message + err500
                })
            })
        },
    },
    getters: {
        list(state) {
            return state.list
        },
        createupdate(state) {
            return state.createupdate
        },
        detail(state) {
            return state.detail
        },
        delbyid(state) {
            return state.delbyid
        },
    }
}
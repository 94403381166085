import api from "./api";
import TokenService from "@/services/tokenService";

export default {
  getListRegion(paramsTerm) {
    api.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getUserToken().access_token}`;
    return api.get(`/authority/data/list/region?term=${paramsTerm}&limit=100&cursor`);
  },

  getListProvince() {
    api.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getUserToken().access_token}`;
    return api.get(`/authority/region/province/list?limit=100`);
  },

  getListRegency(province_id) {
    api.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getUserToken().access_token}`;
    return api.get(`/authority/region/regency/list?province_id=${province_id}&limit=100`);
  },

  getListDistrict(regency_id) {
    api.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getUserToken().access_token}`;
    return api.get(`/authority/region/district/list?regency_id=${regency_id}&limit=100`);
  },

  getListVillage(district_id) {
    api.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getUserToken().access_token}`;
    return api.get(`/authority/region/village/list?district_id=${district_id}&limit=100`);
  },
  getListRt(village_id) {
    api.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getUserToken().access_token}`;
    return api.get(`/authority/data/list/rt?term=&parent_id=${village_id}&limit=50&cursor`);
  },
  getListRw(rt) {
    api.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getUserToken().access_token}`;
    return api.get(`/authority/data/list/rw?term=&parent_id=${rt}&limit=50&cursor`);
  },
};

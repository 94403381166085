export default {
  getUserToken() {
    return JSON.parse(localStorage.getItem("userToken"));
  },

  setUserToken(userToken) {
    localStorage.setItem("userToken", JSON.stringify(userToken));
  },

  updateLocalAccessToken(token) {
    let userToken = JSON.parse(localStorage.getItem("userToken"));
    userToken.access_token = token;
    localStorage.setItem("userToken", JSON.stringify(userToken));
  },

  removeUserToken() {
    localStorage.removeItem("userToken");
  },
};

import api from "./api";

export default {
  getCategoryList() {
    return api.get("partner/data/category?limit=500&term=");
  },
  createPartner(payload) {
    api.defaults.headers.common["Content-Type"] = "multipart/form-data";
    return api.post("/partner/submission/create", payload);
  },
};

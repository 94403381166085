import Axios from "axios";

import AuthService from "@/services/authService";
import TokenService from "@/services/tokenService";
import UserActiveProfileService from "@/services/userActiveProfileService";

const token = TokenService.getUserToken();
const userActiveProfile = UserActiveProfileService.getUserActiveProfile();

export default {
  namespaced: true,
  state: {
    access_token: token ? token.access_token : null,
    token_type: token ? token.token_type : null,
    user_active_profile: userActiveProfile ? userActiveProfile : [],
  },
  getters: {
    getUserToken: (state) => state.access_token,
  },
  mutations: {
    loginSuccess(state, data) {
      TokenService.setUserToken(data.data);
      Axios.defaults.headers.common["Authorization"] = `${data.data.token_type} ${data.data.access_token}`;
      state.access_token = data.data.access_token;
      state.token_type = data.data.token_type;
    },
    logoutSuccess(state) {
      TokenService.removeUserToken();
      UserActiveProfileService.removeUserActiveProfile();
      Axios.defaults.headers.common["Authorization"] = "";
      state.access_token = null;
      state.token_type = null;
    },
    setUserActiveProfile(state, data) {
      UserActiveProfileService.setUserActiveProfile(data);
      state.user_active_profile = data;
    },
  },
  actions: {
    login({ commit }, payload) {
      return AuthService.login(payload)
        .then((response) => {
          if (response.data.status) {
            commit("loginSuccess", response.data);
          }
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    logout({ commit }) {
      return commit("logoutSuccess");
    },
    getProfile({ commit }) {
      return AuthService.getProfile()
        .then((response) => {
          if (response.data.status) {
            commit("setUserActiveProfile", response.data.data);
            return response;
          }
        })
        .catch((error) => {
          console.error(error);
          return error;
        });
    },
  },
};

import WilayahService from "@/services/wilayahServices";

export default {
    namespaced: true,
    state: {
        modalFilterWilayah: {
            isModalShow: false,
            currentData: null,
        },
        listRegion: [],
        listProvince: [],
        listProvince: [],
        listRegency: [],
        listDistrict: [],
        listVillage: [],
        listRt: [],
        listRw: [],
        currentRegion: [],
    },
    mutations: {
        setListRegion(state, data) {
            state.listRegion = data;
        },

        setListProvince(state, data) {
            state.listProvince = data;
        },

        setListRegency(state, data) {
            state.listRegency = data;
        },

        setListDistrict(state, data) {
            state.listDistrict = data;
        },

        setListVillage(state, data) {
            state.listVillage = data;
        },
        setListRt(state, data) {
            state.listRt = data;
        },
        setListRw(state, data) {
            state.listRw = data;
        },
        setCurrentRegion(state, data) {
            state.currentRegion = data;
        },

        toggleModalFilterWilayah(state) {
            state.modalFilterWilayah.currentData = null;
            state.modalFilterWilayah.isModalShow = !state.modalFilterWilayah.isModalShow;
        },
    },
    actions: {
        getListRegion({ commit }, params) {
            return WilayahService.getListRegion(params)
                .then((response) => {
                    commit("setListRegion", response.data.data);
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    return error;
                });
        },

        getListProvince({ commit }, params) {
            return WilayahService.getListProvince(params)
                .then((response) => {
                    console.log('setListProvince : ', response)
                    commit("setListProvince", response.data.data);
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    return error;
                });
        },

        getListRegency({ commit }, params) {
            return WilayahService.getListRegency(params)
                .then((response) => {
                    commit("setListRegency", response.data.data);
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    return error;
                });
        },

        getListDistrict({ commit }, params) {
            return WilayahService.getListDistrict(params)
                .then((response) => {
                    commit("setListDistrict", response.data.data);
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    return error;
                });
        },

        getListVillage({ commit }, params) {
            return WilayahService.getListVillage(params)
                .then((response) => {
                    commit("setListVillage", response.data.data);
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    return error;
                });
        },
        getListRt({ commit }, params) {
            return WilayahService.getListRt(params)
                .then((response) => {
                    commit("setListRt", response.data.data);
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    return error;
                });
        },
        getListRw({ commit }, params) {
            return WilayahService.getListRw(params)
                .then((response) => {
                    commit("setListRw", response.data.data);
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    return error;
                });
        },
        toggleModalFilterWilayah({ commit }) {
            return commit("toggleModalFilterWilayah");
        },

        setCurrentRegion({ commit }, payload) {
            commit("setCurrentRegion", payload);
            commit("toggleModalFilterWilayah");
        },
    },
};

import apiClient from "@/services/api"
import tokenService from "@/services/tokenService"

export default {
    namespaced: true,
    state: {
        lokasi: {
            loading: false,
            data: {
                markers: []
            },
            error: ''
        },
        info: {
            loading: false,
            data: {
                affected: 0,
                region: null,
                in_charge: null
            },
            error: ''
        },
        user: {
            loading: false,
            data: [],
            error: ''
        },
        penanggungjawab: {
            loading: false,
            data: [],
            error: ''
        }
        // userfilled: {
        //     loading: false,
        //     data: [],
        //     error: ''
        // },
        // usernotfilled: {
        //     loading: false,
        //     data: [],
        //     error: ''
        // }
    },
    mutations: {
        setbyLokasi(state, payload) {
            state.lokasi.loading = payload.loading
            state.lokasi.data = payload.data
            if (payload.error != undefined) {
                state.lokasi.error = payload.error
            }
        },
        setInfobyLokasi(state, payload) {
            state.info.loading = payload.loading
            state.info.data = payload.data
            if (payload.error != undefined) {
                state.info.error = payload.error
            }
        },
        setUser(state, payload) {
            state.user.loading = payload.loading
            state.user.data = payload.data
            if (payload.error != undefined) {
                state.user.error = payload.error
            }
        },
        setPenanggungJawab(state, payload) {
            state.penanggungjawab.loading = payload.loading
            state.penanggungjawab.data = payload.data
            if (payload.error != undefined) {
                state.penanggungjawab.error = payload.error
            }
        },
        // setUserFilled(state, payload) {
        //     state.userfilled.loading = payload.loading
        //     state.userfilled.data = payload.data
        //     if (payload.error != undefined) {
        //         state.userfilled.error = payload.error
        //     }
        // },
        // setUserNotFilled(state, payload) {
        //     state.usernotfilled.loading = payload.loading
        //     state.usernotfilled.data = payload.data
        //     if (payload.error != undefined) {
        //         state.usernotfilled.error = payload.error
        //     }
        // },
    },
    actions: {
        _getPenangananbyLokasi({ commit }, params) {

            // province, regency, district, village, rw, rt
            var region_type = params != undefined && params.region_type != undefined ? params.region_type : ''
            // region_id
            var region_id = params != undefined && params.region_id != undefined ? params.region_id : ''
            commit('setbyLokasi', {
                loading: true,
                data: {
                    markers: []
                },
                error: ''
            })
            apiClient.get(`authority/pandemic_spread/region/map?region_type=${region_type}&region_id=${region_id}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                commit('setbyLokasi', {
                    loading: false,
                    data: res.data.data
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setbyLokasi', {
                    loading: false,
                    data: {
                        markers: []
                    },
                    error: message + err500
                })
            })
        },
        _getInfobyLokasi({ commit }, params) {

            // province, regency, district, village, rw, rt
            var region_type = params != undefined && params.region_type != undefined ? params.region_type : ''
            // region_id
            var region_id = params != undefined && params.region_id != undefined ? params.region_id : ''
            commit('setInfobyLokasi', {
                loading: true,
                data: {
                    affected: 0,
                    region: null,
                    in_charge: null
                },
                error: ''
            })
            apiClient.get(`authority/pandemic_spread/region/info?region_type=${region_type}&region_id=${region_id}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                commit('setInfobyLokasi', {
                    loading: false,
                    data: res.data.data
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setInfobyLokasi', {
                    loading: false,
                    data: {
                        affected: 0,
                        region: null,
                        in_charge: null
                    },
                    error: message + err500
                })
            })
        },
        _getUserList({ commit }, params) {

            // province, regency, district, village, rw, rt
            var region_type = params != undefined && params.region_type != undefined ? params.region_type : ''
            // region_id
            var region_id = params != undefined && params.region_id != undefined ? params.region_id : ''
            // term
            var term = params != undefined && params.term != undefined ? params.term : ''
            // user type users_filled/users_not_filled
            var usertype = params != undefined && params.usertype != undefined ? params.usertype : 'users_filled'

            commit('setUser', {
                loading: true,
                data: [],
                error: ''
            })
            apiClient.get(`authority/pandemic_spread/region/${usertype}?region_type=${region_type}&region_id=${region_id}&term=${term}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                commit('setUser', {
                    loading: false,
                    data: res.data.data
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setUser', {
                    loading: false,
                    data: [],
                    error: message + err500
                })
            })
        },
        saveUpdatePenanggungJawab({ commit }, params) {

            var rw_id = params != undefined && params.rw_id != undefined ? params.rw_id : ''
            var name = params != undefined && params.name != undefined ? params.name : ''
            var phone = params != undefined && params.phone != undefined ? params.phone : ''

            commit('setPenanggungJawab', {
                loading: true,
                data: [],
                error: ''
            })
            apiClient.post(`authority/pandemic_spread/region/in_charge?rw_id=${rw_id}&name=${name}&phone=${phone}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {

                if (res.data.status) {
                    commit('setPenanggungJawab', {
                        loading: false,
                        data: res.data.data
                    })
                } else {
                    commit('setPenanggungJawab', {
                        loading: false,
                        data: [],
                        error: res.data.message
                    })
                }

            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setPenanggungJawab', {
                    loading: false,
                    data: [],
                    error: message + err500
                })
            })
        },
        // _getUserFilled({ commit }, params) {

        //     // province, regency, district, village, rw, rt
        //     var region_type = params != undefined && params.region_type != undefined ? params.region_type : ''
        //     // region_id
        //     var region_id = params != undefined && params.region_id != undefined ? params.region_id : ''
        //     // term
        //     var term = params != undefined && params.term != undefined ? params.term : ''

        //     commit('setUserFilled', {
        //         loading: true,
        //         data: [],
        //         error: ''
        //     })
        //     apiClient.get(`authority/pandemic_spread/region/users_filled?region_type=${region_type}&region_id=${region_id}&term=${term}`, {
        //         headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
        //     }).then((res) => {
        //         commit('setUserFilled', {
        //             loading: false,
        //             data: res.data.data
        //         })
        //     }).catch(({ message, response }) => {
        //         var err500 = '';
        //         if (response != undefined && response.status === 500) {
        //             err500 = ' ,' + response.data.message
        //         }
        //         commit('setUserFilled', {
        //             loading: false,
        //             data: [],
        //             error: message + err500
        //         })
        //     })
        // },
        // _getUserNotFilled({ commit }, params) {

        //     // province, regency, district, village, rw, rt
        //     var region_type = params != undefined && params.region_type != undefined ? params.region_type : ''
        //     // region_id
        //     var region_id = params != undefined && params.region_id != undefined ? params.region_id : ''
        //     // term
        //     var term = params != undefined && params.term != undefined ? params.term : ''

        //     commit('setUserNotFilled', {
        //         loading: true,
        //         data: [],
        //         error: ''
        //     })
        //     apiClient.get(`authority/pandemic_spread/region/users_filled?region_type=${region_type}&region_id=${region_id}&term=${term}`, {
        //         headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
        //     }).then((res) => {
        //         commit('setUserNotFilled', {
        //             loading: false,
        //             data: res.data.data
        //         })
        //     }).catch(({ message, response }) => {
        //         var err500 = '';
        //         if (response != undefined && response.status === 500) {
        //             err500 = ' ,' + response.data.message
        //         }
        //         commit('setUserNotFilled', {
        //             loading: false,
        //             data: [],
        //             error: message + err500
        //         })
        //     })
        // },
    },
    getters: {
        penangananbylokasi(state) {
            return state.lokasi
        },
        infobylokasi(state) {
            return state.info
        },
        listuser(state) {
            return state.user
        },
        penanggungjawab(state) {
            return state.penanggungjawab
        },
        // userfilled(state) {
        //     return state.userfilled
        // },
        // usernotfilled(state) {
        //     return state.usernotfilled
        // },
    }
}
import apiClient from "@/services/api"
import tokenService from "@/services/tokenService"

export default {
    namespaced: true,
    state: {
        jusaha: {
            loading: false,
            firts_load: true,
            last_record: false,
            list: [],
            meta: {},
            error: '',
            add: {
                loading: false,
                data: null,
                message: '',
                error: ''
            },
            status: {
                loading: false,
                data: null,
                message: '',
                error: ''
            },
            detail: {
                loading: false,
                data: null,
                error: ''
            },
            update: {
                loading: false,
                data: null,
                message: '',
                error: ''
            },
        }
    },
    mutations: {
        setListJusaha(state, payload) {
            state.jusaha.loading = payload.loading
            state.jusaha.last_record = payload.last_record
            state.jusaha.list = payload.list
            state.jusaha.meta = payload.meta
            if (payload.error != undefined) {
                state.jusaha.error = payload.error
            }
        },
        setAddUsaha(state, payload) {
            state.jusaha.add.loading = payload.loading
            state.jusaha.add.data = payload.data
            state.jusaha.add.message = payload.message
            if (payload.error != undefined) {
                state.jusaha.add.error = payload.error
            }
        },
        setUpdateStatus(state, payload) {
            state.jusaha.status.loading = payload.loading
            state.jusaha.status.data = payload.data
            state.jusaha.status.message = payload.message
            if (payload.error != undefined) {
                state.jusaha.status.error = payload.error
            }
        },
        setDetailJusaha(state, payload) {
            state.jusaha.detail.loading = payload.loading
            state.jusaha.detail.data = payload.data
            if (payload.error != undefined) {
                state.jusaha.error = payload.error
            }
        },
        setUpdate(state, payload) {
            state.jusaha.update.loading = payload.loading
            state.jusaha.update.data = payload.data
            state.jusaha.update.message = payload.message
            if (payload.error != undefined) {
                state.jusaha.update.error = payload.error
            }
        },
    },
    actions: {
        getListJusaha({ commit }, params) {
            var search = params != undefined && params.search != undefined ? params.search : ''
            var status = params != undefined && params.status != undefined ? params.status : ''
            var limit = params != undefined && params.limit != undefined ? params.limit : 10
            var cursor = params != undefined && params.meta != undefined ? params.meta : ''
            var firts_load = params != undefined && params.firts_load != undefined ? params.firts_load : true

            commit('setListJusaha', {
                loading: true,
                list: [],
                meta: {},
                error: '',
            })

            // cursor = cursor.next_cursor != undefined ? cursor.next_cursor : ''
            apiClient.get(`authority/master/category/list?limit=${limit}&cursor=${cursor}&search=${search}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                var last_record = false;
                if (res.data.meta.pagination.next_cursor === null) {
                    last_record = true
                }

                commit('setListJusaha', {
                    loading: false,
                    list: res.data.data,
                    meta: res.data.meta,
                    firts_load: firts_load,
                    last_record: last_record
                })
            }).catch(({ message, response }) => {
                // console.log('catch response :', response)
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setListJusaha', {
                    loading: false,
                    list: [],
                    firts_load: firts_load,
                    error: message + err500
                })
            })
        },
        _addUsaha({ commit }, params) {

            var name = params != undefined && params.name != undefined ? params.name : ''
            var description = params != undefined && params.description != undefined ? params.description : ''
            var icon_1 = params != undefined && params.icon_1 != undefined ? params.icon_1 : ''
            var icon_2 = params != undefined && params.icon_2 != undefined ? params.icon_2 : ''
            var fee_booking = params != undefined && params.fee_booking != undefined ? params.fee_booking : ''
            var fee_booking_type = params != undefined && params.fee_booking_type != undefined ? params.fee_booking_type : ''
            var fee_cancel_booking = params != undefined && params.fee_cancel_booking != undefined ? params.fee_cancel_booking : ''
            var fee_cancel_booking_type = params != undefined && params.fee_cancel_booking_type != undefined ? params.fee_cancel_booking_type : ''

            commit('setAddUsaha', {
                loading: true,
                data: '',
                message: '',
                error: ''
            })

            var field = new FormData();
            field.append('name', name)
            field.append('description', description)
            field.append('icon_1', icon_1)
            field.append('icon_2', icon_2)
            field.append('fee_booking', fee_booking)
            field.append('fee_booking_type', fee_booking_type)
            field.append('fee_cancel_booking', fee_cancel_booking)
            field.append('fee_cancel_booking_type', fee_cancel_booking_type)

            apiClient.post(`authority/master/category/create`, field, {
                headers: {
                    "Authorization": `Bearer ${tokenService.getUserToken().access_token}`,
                    'Content-Type': 'multipart/form-data'
                },
            }).then((res) => {

                var err = ''
                if (!res.data.status) {
                    err = res.data.message
                }
                commit('setAddUsaha', {
                    loading: false,
                    data: res.data.data,
                    message: '',
                    error: err
                });

            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setAddUsaha', {
                    loading: false,
                    message: '',
                    data: null,
                    error: message + err500
                })
            })
        },
        _setUpdateStatus({ commit }, params) {

            var id = params != undefined && params.id != undefined ? params.id : ''
            var status = params != undefined && params.status != undefined ? params.status : ''

            commit('setUpdateStatus', {
                loading: true,
                data: '',
                message: '',
                error: ''
            })

            var field = new FormData();
            field.append('id', id)
            field.append('status', status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')

            apiClient.post(`authority/master/category/update-status`, field, {
                headers: {
                    "Authorization": `Bearer ${tokenService.getUserToken().access_token}`,
                    'Content-Type': 'multipart/form-data'
                },
            }).then((res) => {

                commit('setUpdateStatus', {
                    loading: false,
                    data: res.data.data,
                    message: '',
                    error: ''
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setStatus', {
                    loading: false,
                    message: '',
                    data: null,
                    error: message + err500
                })
            })
        },
        _getDetail({ commit }, id) {
            // var id = params != undefined && params.id != undefined ? params.id : ''

            commit('setDetailJusaha', {
                loading: true,
                data: null,
                error: ''
            })
            apiClient.get(`authority/master/category/detail?id=${id}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                commit('setDetailJusaha', {
                    loading: false,
                    data: res.data.data,
                    error: ''
                })
            }).catch(({ message, response }) => {

                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setDetailJusaha', {
                    loading: false,
                    data: null,
                    error: message + err500
                })
            })
        },
        _setUpdate({ commit }, params) {

            var id = params != undefined && params.id != undefined ? params.id : ''
            var name = params != undefined && params.name != undefined ? params.name : ''
            var description = params != undefined && params.description != undefined ? params.description : ''
            var icon_1 = params != undefined && params.icon_1 != undefined ? params.icon_1 : ''
            var icon_2 = params != undefined && params.icon_2 != undefined ? params.icon_2 : ''
            var fee_booking = params != undefined && params.fee_booking != undefined ? params.fee_booking : ''
            var fee_booking_type = params != undefined && params.fee_booking_type != undefined ? params.fee_booking_type : ''
            var fee_cancel_booking = params != undefined && params.fee_cancel_booking != undefined ? params.fee_cancel_booking : ''
            var fee_cancel_booking_type = params != undefined && params.fee_cancel_booking_type != undefined ? params.fee_cancel_booking_type : ''

            commit('setUpdate', {
                loading: true,
                data: '',
                message: '',
                error: ''
            })

            var field = new FormData();
            field.append('id', id)
            field.append('name', name)
            field.append('description', description)
            if (icon_1 != '') {
                field.append('icon_1', icon_1)
            }
            if (icon_2 != '') {
                field.append('icon_2', icon_2)
            }

            field.append('fee_booking', fee_booking)
            field.append('fee_booking_type', fee_booking_type)
            field.append('fee_cancel_booking', fee_cancel_booking)
            field.append('fee_cancel_booking_type', fee_cancel_booking_type)

            apiClient.post(`authority/master/category/update`, field, {
                headers: {
                    "Authorization": `Bearer ${tokenService.getUserToken().access_token}`,
                    'Content-Type': 'multipart/form-data'
                },
            }).then((res) => {

                var err = ''
                if (!res.data.status) {
                    err = res.data.message
                }
                commit('setUpdate', {
                    loading: false,
                    data: res.data.data,
                    message: '',
                    error: err
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setUpdate', {
                    loading: false,
                    message: '',
                    data: null,
                    error: message + err500
                })
            })
        },
    },
    getters: {
        listjusaha(state) {
            return state.jusaha
        },
        addjusaha(state) {
            return state.jusaha.add
        },
        upstatusjusaha(state) {
            return state.jusaha.status
        },
        detailjusaha(state) {
            return state.jusaha.detail
        },
        updateusaha(state) {
            return state.jusaha.update
        },
    },
}
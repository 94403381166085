export default {
  namespaced: true,
  state: {
    isSidebarShow: true,
  },
  getters: {},
  mutations: {
    toggleSidebar(state) {
      state.isSidebarShow = !state.isSidebarShow;
    },
  },
  actions: {
    toggleSidebar({ commit }) {
      return commit("toggleSidebar");
    },
  },
};

import api from "./api";

export default {
  getListProvince() {
    api.defaults.headers.common["Authorization"] = `Bearer 9571D127-4B07-47E2-A69E-58E3053759E1|sIb7d0bvmhO995RCy9tUzZcPPnmpH3TrAAwOJ6go`;

    return api.get("/user/data/list/province");
  },
  getListRegency(parentId) {
    api.defaults.headers.common["Authorization"] = `Bearer 9571D127-4B07-47E2-A69E-58E3053759E1|sIb7d0bvmhO995RCy9tUzZcPPnmpH3TrAAwOJ6go`;

    return api.get(`/user/data/list/regency?parent_id=${parentId}`);
  },
  getListDistrict(parentId) {
    api.defaults.headers.common["Authorization"] = `Bearer 9571D127-4B07-47E2-A69E-58E3053759E1|sIb7d0bvmhO995RCy9tUzZcPPnmpH3TrAAwOJ6go`;

    return api.get(`/user/data/list/district?parent_id=${parentId}`);
  },
  getListVillage(parentId) {
    api.defaults.headers.common["Authorization"] = `Bearer 9571D127-4B07-47E2-A69E-58E3053759E1|sIb7d0bvmhO995RCy9tUzZcPPnmpH3TrAAwOJ6go`;

    return api.get(`/user/data/list/village?parent_id=${parentId}`);
  },
};

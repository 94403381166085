import UserService from "@/services/userService";

export default {
  namespaced: true,
  state: { listProvince: [], listRegency: [] },
  mutations: {
    setListProvince(state, data) {
      state.listProvince = data;
    },
    setListRegency(state, data) {
      state.listRegency = data;
    },
  },
  actions: {
    getListProvince({ commit }) {
      return UserService.getListProvince()
        .then((response) => commit("setListProvince", response.data.data))
        .catch((error) => console.log(error));
    },
    getListRegency({ commit }, payload) {
      return UserService.getListRegency(payload)
        .then((response) => {
          commit("setListRegency", response.data.data);
        })
        .catch((error) => console.log(error));
    },
  },
};
import apiClient from "@/services/api"
import tokenService from "@/services/tokenService"

export default {
    namespaced: true,
    state: {
        pengajuan: {
            loading: false,
            firts_load: true,
            last_record: false,
            filter: {
                limit: 10,
                search: '',
            },
            list: [],
            meta: {},
            error: '',
            approved:{
                loading: false,
                data: null,
                message: '',
                error: ''
            }
        },
        pembayaran:{
            loading: false,
            firts_load: true,
            last_record: false,
            filter: {
                limit: 10,
                search: '',
            },
            list: [],
            meta: {},
            error: '',
            buktitf:{
                loading: false,
                data: null,
                message: '',
                error: ''
            }
        },
        detail: {
            loading: false,
            data: null,
            message: '',
            error: ''
        },
    },
    mutations: {
        setListPengajuan(state, payload) {
            state.pengajuan.loading = payload.loading
            state.pengajuan.last_record = payload.last_record
            if (payload.list != undefined) {
                if (payload.firts_load) {
                    state.pengajuan.list = payload.list
                } else {
                    state.pengajuan.list.push(...payload.list)
                }
            }
            state.pengajuan.meta = payload.meta
            if (payload.error != undefined) {
                state.pengajuan.error = payload.error
            }
        },
        setApproved(state, payload) {
            state.pengajuan.approved.loading = payload.loading
            state.pengajuan.approved.data = payload.data
            state.pengajuan.approved.message = payload.message
            if (payload.error != undefined) {
                state.pengajuan.approved.error = payload.error
            }
        },
        setListPembayaran(state, payload) {
            state.pembayaran.loading = payload.loading
            state.pembayaran.last_record = payload.last_record
            if (payload.list != undefined) {
                if (payload.firts_load) {
                    state.pembayaran.list = payload.list
                } else {
                    state.pembayaran.list.push(...payload.list)
                }
            }
            state.pembayaran.meta = payload.meta
            if (payload.error != undefined) {
                state.pembayaran.error = payload.error
            }
        },
        setDetail(state, payload) {
            state.detail.loading = payload.loading
            state.detail.data = payload.data
            if (payload.error != undefined) {
                state.detail.error = payload.error
            }
        },
        setBuktiTF(state, payload) {
            state.pembayaran.buktitf.loading = payload.loading
            state.pembayaran.buktitf.data = payload.data
            state.pembayaran.buktitf.message = payload.message
            if (payload.error != undefined) {
                state.pembayaran.buktitf.error = payload.error
            }
        },
    },
    actions: {
        _getListPengajuan({ commit }, params) {
            var search = params != undefined && params.search != undefined ? params.search : ''
            var status = params != undefined && params.status != undefined ? params.status : 'APPROVED'
            var limit = params != undefined && params.limit != undefined ? params.limit : 10
            var cursor = params != undefined && params.meta != undefined ? params.meta : ''
            var firts_load = params != undefined && params.firts_load != undefined ? params.firts_load : true
            var firts_load = params != undefined && params.firts_load != undefined ? params.firts_load : true

            commit('setListPengajuan', {
                loading: true,
                firts_load: firts_load,
                list: [],
                meta: {},
                error: '',
            })

            cursor = cursor.next_cursor != undefined ? cursor.next_cursor : ''
            apiClient.get(`authority/refund/list-approval?status=${status}&search=${search}&limit=${limit}&cursor=${cursor}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                // console.log(res)
                var last_record = false;
                if (res.data.meta.pagination.next_cursor === null) {
                    last_record = true
                }

                commit('setListPengajuan', {
                    loading: false,
                    list: res.data.data,
                    meta: res.data.meta,
                    firts_load: firts_load,
                    last_record: last_record
                })
            }).catch(({ message, response }) => {
                // console.log('catch response :', response)
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setListPengajuan', {
                    loading: false,
                    firts_load: firts_load,
                    error: message + err500
                })
            })
        },
        _getListPembayaran({ commit }, params) {
            var search = params != undefined && params.search != undefined ? params.search : ''
            var status = params != undefined && params.status != undefined ? params.status : 'APPROVED'
            var limit = params != undefined && params.limit != undefined ? params.limit : 10
            var cursor = params != undefined && params.meta != undefined ? params.meta : ''
            var firts_load = params != undefined && params.firts_load != undefined ? params.firts_load : true

            console.log(params)

            commit('setListPembayaran', {
                loading: true,
                firts_load: firts_load,
                list: [],
                meta: {},
                error: '',
            })

            cursor = cursor.next_cursor != undefined ? cursor.next_cursor : ''
            apiClient.get(`authority/refund/list-payment?status=${status}&search=${search}&limit=${limit}&cursor=${cursor}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                // console.log(res)
                var last_record = false;
                if (res.data.meta.pagination.next_cursor === null) {
                    last_record = true
                }

                commit('setListPembayaran', {
                    loading: false,
                    list: res.data.data,
                    meta: res.data.meta,
                    firts_load: firts_load,
                    last_record: last_record
                })
            }).catch(({ message, response }) => {
                // console.log('catch response :', response)
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setListPengajuan', {
                    loading: false,
                    firts_load: firts_load,
                    error: message + err500
                })
            })
        },
        _setApproved({ commit }, params) {

            var id = params != undefined && params.id != undefined ? params.id : ''
            var status = params != undefined && params.status != undefined ? params.status : ''

            commit('setApproved', {
                loading: true,
                data: null,
                message: '',
                error: ''
            })

            var appFormdata = new FormData();
            appFormdata.append('id', id)
            appFormdata.append('status', status)
            apiClient.put(`authority/refund/update-status?id=${id}&status=${status}`,appFormdata, {
                headers: {
                    "Authorization": `Bearer ${tokenService.getUserToken().access_token}`,
                    'Content-Type': 'multipart/form-data'
                },
            }).then((res) => {
                commit('setApproved', {
                    loading: false,
                    data: res.data.data,
                    message: res.data.message,
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setApproved', {
                    loading: false,
                    message: '',
                    data: null,
                    error: message + err500
                })
            })
        },
        _getDetail({ commit }, id) {
            // var id = params != undefined && params.id != undefined ? params.id : ''
            commit('setDetail', {
                loading: true,
                data: null,
                error: ''
            })
            apiClient.get(`authority/refund/detail?id=${id}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                commit('setDetail', {
                    loading: false,
                    data: res.data.data.length > 0?res.data.data[0]:null
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setDetail', {
                    loading: false,
                    data: null,
                    error: message + err500
                })
            })
        },
        _setBuktiTF({ commit }, params) {
            var id = params != undefined && params.id != undefined ? params.id : ''
            var note = params != undefined && params.note != undefined ? params.note : ''
            var file = params != undefined && params.file != undefined ? params.file : ''

            commit('setBuktiTF', {
                loading: true,
                data: null,
                message: '',
                error: ''
            })

            var appFormdata = new FormData();
            appFormdata.append('id', id)
            appFormdata.append('note', note)
            appFormdata.append('file', file)
            apiClient.post(`authority/refund/upload-transfer`, appFormdata, {
                headers: {
                    "Authorization": `Bearer ${tokenService.getUserToken().access_token}`,
                    'Content-Type': 'multipart/form-data'
                },
            }).then((res) => {
                commit('setBuktiTF', {
                    loading: false,
                    data: res.data.data,
                    message: res.data.message,
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setBuktiTF', {
                    loading: false,
                    message: '',
                    data: null,
                    error: message + err500
                })
            })
        },
    },
    getters: {
        pengajuan(state) {
            return state.pengajuan
        },
        approved(state) {
            return state.pengajuan.approved
        },
        pembayaran(state) {
            return state.pembayaran
        },
        details(state) {
            return state.detail
        },
        buktitf(state) {
            return state.pembayaran.buktitf
        },
    }
}
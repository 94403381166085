import AdminService from "@/services/adminService";

export default {
  namespaced: true,
  state: {
    listAdmin: { data: [], pagination: {} },
    modalAdmin: {
      isModalShow: false,
      currentData: null,
    },
  },
  getters: {
    getListAdmin: (state) => state.listAdmin.data,
  },
  mutations: {
    getListAdmin(state, data) {
      state.listAdmin.data = data;
    },
    setPaginationListAdmin(state, data) {
      state.listAdmin.pagination = data;
    },
    toggleModalAdmin(state) {
      state.modalAdmin.currentData = null;
      state.modalAdmin.isModalShow = !state.modalAdmin.isModalShow;
    },
    setEditDataModalAdmin(state, data) {
      state.modalAdmin.currentData = data;
    },
  },
  actions: {
    createTestHealth({ commit }, payload) {
      return AdminService.createTestHealth(payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    getListAdmin({ commit }) {
      return AdminService.getListAdmin()
        .then((response) => {
          commit("getListAdmin", response.data.data);
          commit("setPaginationListAdmin", response.data.meta.pagination);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createAdmin({}, payload) {
      return AdminService.createAdmin(payload).catch((error) => console.log(error));
    },
    getDetailAdmin({ commit }, payload) {
      return AdminService.getDetailAdmin(payload)
        .then((response) => {
          commit("toggleModalAdmin");
          commit("setEditDataModalAdmin", response.data.data);
        })
        .catch((error) => console.log(error));
    },
    updateAdmin({}, payload) {
      return AdminService.updateAdmin(payload).catch((error) => console.log(error));
    },
    deleteAdmin({}, payload) {
      return AdminService.deleteAdmin(payload).catch((error) => console.log(error));
    },
    setEditDataModalAdmin({ commit }, payload) {
      commit("toggleModalAdmin");
      commit("setEditDataModalRole", payload);
    },
    toggleModalAdmin({ commit }) {
      return commit("toggleModalAdmin");
    },
  },
};

export default {
  getUserActiveProfile() {
    return JSON.parse(localStorage.getItem("userActiveProfile"));
  },

  setUserActiveProfile(userProfile) {
    localStorage.setItem("userActiveProfile", JSON.stringify(userProfile));
  },

  removeUserActiveProfile() {
    localStorage.removeItem("userActiveProfile");
  },
};

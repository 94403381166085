import api from "./api";
import TokenService from "@/services/tokenService";

export default {
  getListSectionApp() {
    return api.get("/authority/section/list");
  },
  getDetailSectionApp(id) {
    return api.get(`/authority/section/detail?id=${id}`);
  },
  createSectionApp(payload) {
    return api.post("/authority/section/create", payload);
  },
  updateSectionApp(payload) {
    return api.post("/authority/section/update", payload);
  },
  deleteSectionApp(payload) {
    return api.post("/authority/section/delete", payload);
  },

  getListCategory() {
    api.defaults.headers.common["Authorization"] = `Bearer ${
      TokenService.getUserToken().access_token
    }`;
    return api.get("/authority/master/category/list");
  },

  getListActiveHappening() {
    api.defaults.headers.common["Authorization"] = `Bearer ${
      TokenService.getUserToken().access_token
    }`;
    return api.get("/authority/happening/active/list");
  },
};

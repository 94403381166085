import apiClient from "@/services/api"
import tokenService from "@/services/tokenService"
import moment from "moment"

export default {
    namespaced: true,
    state: {
        summary: {
            chart: {
                loading: false,
                data: {
                    categories: [],
                    series: []
                },
                error: ''
            },
            status: {
                loading: false,
                data: {
                    suspect: {
                        number: 0,
                        from_last_month: 0
                    },
                    affected: {
                        number: 0,
                        from_last_month: 0
                    },
                },
                error: ''
            },
            perwilayah: {
                loading: false,
                data: null,
                error: ''
            }
        },
        // penanganan: {
        //     lokasi: {
        //         loading: false,
        //         data: [],
        //         error: ''
        //     }
        // }
    },
    mutations: {
        setChartSummary(state, payload) {
            state.summary.chart.loading = payload.loading
            state.summary.chart.data = payload.data
            if (payload.error != undefined) {
                state.summary.chart.error = payload.error
            }
        },
        setStatusSummary(state, payload) {
            state.summary.status.loading = payload.loading
            state.summary.status.data = payload.data
            if (payload.error != undefined) {
                state.summary.status.error = payload.error
            }
        },
        setPerWilayah(state, payload) {
            state.summary.perwilayah.loading = payload.loading
            state.summary.perwilayah.data = payload.data
            if (payload.error != undefined) {
                state.summary.perwilayah.error = payload.error
            }
        },
        // setPenangananbyLokasi(state, payload) {
        //     state.penanganan.lokasi.loading = payload.loading
        //     state.penanganan.lokasi.data = payload.data
        //     if (payload.error != undefined) {
        //         state.penanganan.lokasi.error = payload.error
        //     }
        // },
    },
    actions: {
        _getChartSummary({ commit }, params) {

            // filter_type : affected, suspect
            var filter_type = params != undefined && params.filter_type != undefined ? params.filter_type : 'affected'
            // date_type : month, year
            var date_type = params != undefined && params.date_type != undefined ? params.date_type : 'month'

            var str_filtertype = '';
            var str_filterdate = '';
            switch (filter_type) {
                case 'affected':
                    str_filtertype = 'AFFECTED'
                    break;
                case 'suspect':
                    str_filtertype = 'SUSPECT'
                    break;
                default:
                    str_filtertype = 'AFFECTED'
                    break;
            }

            switch (date_type) {
                case 'month':
                    str_filterdate = 'Berdasarkan Bulan'
                    break;
                case 'year':
                    str_filterdate = 'Berdasarkan Tahun'
                    break;
                default:
                    str_filterdate = 'Berdasarkan Bulan'
                    break;
            }

            commit('setChartSummary', {
                loading: true,
                data: {
                    categories: [],
                    series: []
                },
                error: ''
            })
            apiClient.get(`authority/pandemic_spread/summary/chart?filter_type=${filter_type}&date_type=${date_type}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {

                let data = res.data.data
                let categories_before = [];
                let series_before = [];
                let categories_current = [];
                let series_current = [];
                let categories = [];

                if (data.before?.length > 0) {
                    for (let index = 0; index < data.before.length; index++) {
                        categories_before.push(moment(data.before[index].date).format('l'))
                        series_before.push(data.before[index].count)
                    }
                } else {
                    if (data.current?.length > 0) {
                        for (let index = 0; index < data.current?.length; index++) {
                            series_before.push(0)
                        }
                    } else {
                        series_before.push(0)
                    }
                }

                if (data.current?.length > 0) {
                    for (let index = 0; index < data.current.length; index++) {
                        categories_current.push(moment(data.current[index].date).format('l'))
                        series_current.push(data.current[index].count)
                    }
                } else {
                    if (data.before?.length > 0) {
                        for (let index = 0; index < data.before?.length; index++) {
                            series_current.push(0)
                        }

                    } else {
                        series_current.push(0)
                    }

                }
                categories = categories_before.concat(categories_current.filter((item) => categories_before.indexOf(item) < 0))

                let payload = {
                    categories: categories,
                    series: [
                        {
                            name: 'Bulan Lalu',
                            data: series_before
                        },
                        {
                            name: 'Bulan Ini',
                            data: series_current
                        }
                    ]
                }
                commit('setChartSummary', {
                    loading: false,
                    data: payload,
                    error: ''
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setChartSummary', {
                    loading: false,
                    data: {
                        categories: [],
                        series: []
                    },
                    error: message + err500
                })
            })
        },
        _getStatusSummary({ commit }) {
            commit('setStatusSummary', {
                loading: true,
                data: {
                    suspect: 0,
                    affected: 0,
                },
                error: ''
            })
            apiClient.get(`authority/pandemic_spread/summary/number`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {

                var payload = {
                    affected: res.data.data.affected,
                    suspect: res.data.data.suspect,
                }
                console.log(res.data.data.affected)
                commit('setStatusSummary', {
                    loading: false,
                    data: payload
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setStatusSummary', {
                    loading: false,
                    data: {
                        suspect: 0,
                        affected: 0,
                    },
                    error: message + err500
                })
            })
        },
        _getPerWilayah({ commit }, params) {
            // optional
            var district = params != undefined && params.district != undefined ? params.district : ''
            // optional
            var village = params != undefined && params.village != undefined ? params.village : ''
            commit('setPerWilayah', {
                loading: true,
                data: null,
                error: ''
            })
            apiClient.get(`authority/pandemic_spread/summary/info?district=${district}&village=${village}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {

                commit('setPerWilayah', {
                    loading: false,
                    data: res.data.data
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setPerWilayah', {
                    loading: false,
                    data: null,
                    error: message + err500
                })
            })
        },
    },
    getters: {
        // SUMMARY
        chartsummary(state) {
            return state.summary.chart
        },
        statussummary(state) {
            return state.summary.status
        },
        perwilayah(state) {
            return state.summary.perwilayah
        },
        // penangananbylokasi(state) {
        //     return state.penanganan.lokasi
        // },
    },
}
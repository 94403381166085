import apiClient from "@/services/api"
import tokenService from "@/services/tokenService"

export default {
    namespaced: true,
    state: {
        list: {
            loading: false,
            data: [],
            error: ''
        }
    },
    mutations: {
        setList(state, payload) {
            state.list.loading = payload.loading
            state.list.data = payload.data
            if (payload.error != undefined) {
                state.list.error = payload.error
            }
        },
    },
    actions: {
        _getListKategori({ commit }) {
            commit('setList', {
                loading: true,
                data: [],
                error: ''
            })
            apiClient.get(`authority/data/list/category?term=&limit=100&cursor`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                commit('setList', {
                    loading: false,
                    data: res.data.data
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setList', {
                    loading: false,
                    data: [],
                    error: message + err500
                })
            })
        },
    },
    getters: {
        list_kategori(state) {
            return state.list
        },
    }
}
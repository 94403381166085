import apiClient from "@/services/api"
import tokenService from "@/services/tokenService"
import moment from "moment"

export default {
    namespaced: true,
    state: {
        berlangsung: {
            loading: false,
            firts_load: true,
            last_record: false,
            filter: {
                limit: 10,
                search: '',
                health: ''
            },
            list: [],
            meta: {},
            detail: {
                loading: false,
                data: null
            },
            error: ''
        },
        riwayat: {
            loading: false,
            firts_load: true,
            last_record: false,
            filter: {
                limit: 10,
                search: '',
                health: ''
            },
            list: [],
            meta: {},
            detail: {
                loading: false,
                data: null
            },
            error: ''
        },
        kumpulbersama: {
            chart: {
                loading: false,
                data: {
                    categories: [],
                    series: []
                },
                error: ''
            },
            meetup: {
                loading: false,
                data: {},
                error: ''
            },
            top: {
                terbanyak: {
                    loading: false,
                    error: '',
                    data: {}
                },
                status: {
                    loading: false,
                    error: '',
                    data: {}
                },
            }
        },
    },
    mutations: {
        // KEGIATAN BERLANGSUNG
        setListBerlangsung(state, payload) {
            state.berlangsung.loading = payload.loading
            state.berlangsung.last_record = payload.last_record
            if (payload.list != undefined) {
                if (payload.firts_load) {
                    state.berlangsung.list = payload.list
                } else {
                    state.berlangsung.list.push(...payload.list)
                }
            }
            state.berlangsung.meta = payload.meta
            if (payload.error != undefined) {
                state.berlangsung.error = payload.error
            }
        },
        setDetailBerlangsung(state, payload) {
            state.berlangsung.detail.loading = payload.loading
            state.berlangsung.detail.data = payload.data
            if (payload.error != undefined) {
                state.berlangsung.error = payload.error
            }
        },

        // RIWAYAT KEGIATAN
        // setFirstLoadRiwayat(state, payload) {
        //     state.riwayat.firts_load = payload.firts_load
        // },
        setListRiwayat(state, payload) {
            state.riwayat.loading = payload.loading
            state.riwayat.last_record = payload.last_record
            if (payload.list != undefined) {
                if (payload.firts_load) {
                    state.riwayat.list = payload.list
                } else {
                    state.riwayat.list.push(...payload.list)
                }
            }
            state.riwayat.meta = payload.meta
            if (payload.error != undefined) {
                state.riwayat.error = payload.error
            }
        },
        setDetailRiwayat(state, payload) {
            state.riwayat.detail.loading = payload.loading
            state.riwayat.detail.data = payload.data
            if (payload.error != undefined) {
                state.riwayat.error = payload.error
            }
        },

        // KUMPUL BERSAMAN
        setChartKumpulBersama(state, payload) {
            state.kumpulbersama.chart.loading = payload.loading
            state.kumpulbersama.chart.data = payload.data
            if (payload.error != undefined) {
                state.kumpulbersama.error = payload.error
            }
        },
        setMeetup(state, payload) {
            state.kumpulbersama.meetup.loading = payload.loading
            state.kumpulbersama.meetup.data = payload.data
            if (payload.error != undefined) {
                state.kumpulbersama.error = payload.error
            }
        },
        setTopTerbanyak(state, payload) {
            state.kumpulbersama.top.terbanyak.loading = payload.loading
            state.kumpulbersama.top.terbanyak.data = payload.data
            if (payload.error != undefined) {
                state.kumpulbersama.top.terbanyak.error = payload.error
            }
        },
        setTopStatus(state, payload) {
            state.kumpulbersama.top.status.loading = payload.loading
            state.kumpulbersama.top.status.data = payload.data
            if (payload.error != undefined) {
                state.kumpulbersama.top.status.error = payload.error
            }
        }
    },
    getters: {
        // KEGIATAN BERLANGSUNG
        filter_berlangsung(state) {
            return state.berlangsung.filter
        },
        loading_list_berlangsung(state) {
            return state.berlangsung.loading
        },
        list_berlangsung(state) {
            return state.berlangsung.list
        },
        meta_berlangsung(state) {
            return state.berlangsung.meta
        },
        detail_berlangsung_loading(state) {
            return state.berlangsung.detail.loading
        },
        detail_berlangsung_data(state) {
            return state.berlangsung.detail.data
        },
        last_recored_list_berlangsung(state) {
            return state.berlangsung.last_record
        },
        error_berlangsung(state) {
            return state.berlangsung.error
        },

        // RIWAYAT KEGIATAN
        filter_riwayat(state) {
            return state.riwayat.filter
        },
        // firts_load_riwayat(state) {
        //     return state.riwayat.firts_load
        // },
        loading_list_riwayat(state) {
            return state.riwayat.loading
        },
        list_riwayat(state) {
            return state.riwayat.list
        },
        meta_riwayat(state) {
            return state.riwayat.meta
        },
        detail_riwayat_loading(state) {
            return state.riwayat.detail.loading
        },
        detail_riwayat_data(state) {
            return state.riwayat.detail.data
        },
        last_recored_list_riwayat(state) {
            return state.riwayat.last_record
        },
        error_riwayat(state) {
            return state.riwayat.error
        },
        // KUMPUL BERSAMAN
        chartkumpulbersama(state) {
            return state.kumpulbersama.chart
        },
        meetup(state) {
            return state.kumpulbersama.meetup
        },
        topterbanyak(state) {
            return state.kumpulbersama.top.terbanyak
        },
        topstatus(state) {
            return state.kumpulbersama.top.status
        },
    },
    actions: {
        _getListBerlangsung({ commit }, params) {
            var search = params != undefined && params.search != undefined ? params.search : ''
            var health = params != undefined && params.health != undefined ? params.health : ''
            var limit = params != undefined && params.limit != undefined ? params.limit : 10
            var cursor = params != undefined && params.meta != undefined ? params.meta : ''
            var firts_load = params != undefined && params.firts_load != undefined ? params.firts_load : true

            commit('setListBerlangsung', {
                loading: true,
                firts_load: firts_load,
                list: [],
                meta: {},
                error: '',
            })

            cursor = cursor.next_cursor != undefined ? cursor.next_cursor : ''
            apiClient.get(`authority/meetup/current?limit=${limit}&cursor=${cursor}&search=${search}&health=${health}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {

                var last_record = false;
                if (res.data.meta.pagination.next_cursor === null) {
                    last_record = true
                }

                commit('setListBerlangsung', {
                    loading: false,
                    list: res.data.data,
                    meta: res.data.meta,
                    firts_load: firts_load,
                    last_record: last_record
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setListBerlangsung', {
                    loading: false,
                    firts_load: firts_load,
                    error: message + err500
                })
            })
        },
        _getDetailBerlangsung({ commit }, id) {
            // var id = params != undefined && params.id != undefined ? params.id : ''

            commit('setDetailBerlangsung', {
                loading: true,
                error: ''
            })
            apiClient.get(`authority/meetup/detail?id=${id}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                commit('setDetailBerlangsung', {
                    loading: false,
                    data: res.data.data
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setDetailBerlangsung', {
                    loading: false,
                    error: message + err500
                })
            })
        },

        _getListRiwayat({ commit }, params) {
            var search = params != undefined && params.search != undefined ? params.search : ''
            var health = params != undefined && params.health != undefined ? params.health : ''
            var limit = params != undefined && params.limit != undefined ? params.limit : 10
            var cursor = params != undefined && params.meta != undefined ? params.meta : ''
            var firts_load = params != undefined && params.firts_load != undefined ? params.firts_load : true

            commit('setListRiwayat', {
                loading: true,
                firts_load: firts_load,
                list: [],
                meta: {},
                error: '',
            })

            cursor = cursor.next_cursor != undefined ? cursor.next_cursor : ''
            apiClient.get(`authority/meetup/history?limit=${limit}&cursor=${cursor}&search=${search}&health=${health}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {

                var last_record = false;
                if (res.data.meta.pagination.next_cursor === null) {
                    last_record = true
                }

                commit('setListRiwayat', {
                    loading: false,
                    list: res.data.data,
                    meta: res.data.meta,
                    firts_load: firts_load,
                    last_record: last_record
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setListRiwayat', {
                    loading: false,
                    firts_load: firts_load,
                    error: message + err500
                })
            })
        },
        _getDetailRiwayat({ commit }, id) {
            commit('setDetailRiwayat', {
                loading: true,
                error: ''
            })
            apiClient.get(`authority/meetup/detail?id=${id}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                commit('setDetailRiwayat', {
                    loading: false,
                    data: res.data.data
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setDetailRiwayat', {
                    loading: false,
                    error: message + err500
                })
            })

        },
        _getChartKumpulBersama({ commit }, params) {

            // filter_type : total_meetup, ongoing_meetup
            var filter_type = params != undefined && params.filter_type != undefined ? params.filter_type : 'total_meetup'
            // date_type : month, year
            var date_type = params != undefined && params.date_type != undefined ? params.date_type : 'month'

            var str_filtertype = '';
            var str_filterdate = '';
            switch (filter_type) {
                case 'total_meetup':
                    str_filtertype = 'Total Kegiatan Kumpul Bersama'
                    break;
                case 'ongoing_meetup':
                    str_filtertype = 'Total Kegiatan Berlangsung'
                    break;
                default:
                    str_filtertype = 'Total Kegiatan Kumpul Bersama'
                    break;
            }

            switch (date_type) {
                case 'month':
                    str_filterdate = 'Berdasarkan Bulan'
                    break;
                case 'year':
                    str_filterdate = 'Berdasarkan Tahun'
                    break;
                default:
                    str_filterdate = 'Berdasarkan Bulan'
                    break;
            }

            commit('setChartKumpulBersama', {
                loading: true,
                data: {
                    categories: [],
                    series: []
                },
                error: ''
            })
            apiClient.get(`authority/meetup/chart?filter_type=${filter_type}&date_type=${date_type}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {

                let data = res.data.data
                let categories_before = [];
                let series_before = [];
                let categories_current = [];
                let series_current = [];
                let categories = [];

                if (data.before?.length > 0) {
                    for (let index = 0; index < data.before.length; index++) {
                        categories_before.push(moment(data.before[index].date).format('l'))
                        series_before.push(data.before[index].count)
                    }
                } else {
                    if (data.current?.length > 0) {
                        for (let index = 0; index < data.current?.length; index++) {
                            series_before.push(0)
                        }
                    } else {
                        series_before.push(0)
                    }
                }

                if (data.current?.length > 0) {
                    for (let index = 0; index < data.current.length; index++) {
                        categories_current.push(moment(data.current[index].date).format('l'))
                        series_current.push(data.current[index].count)
                    }
                } else {
                    if (data.before?.length > 0) {
                        for (let index = 0; index < data.before?.length; index++) {
                            series_current.push(0)
                        }

                    } else {
                        series_current.push(0)
                    }

                }
                categories = categories_before.concat(categories_current.filter((item) => categories_before.indexOf(item) < 0))

                let payload = {
                    categories: categories,
                    series: [
                        {
                            name: 'Bulan Lalu',
                            data: series_before
                        },
                        {
                            name: 'Bulan Ini',
                            data: series_current
                        }
                    ]
                }

                // console.log(payload)
                commit('setChartKumpulBersama', {
                    loading: false,
                    data: payload,
                    error: ''
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setChartKumpulBersama', {
                    loading: false,
                    data: {
                        categories: [],
                        series: []
                    },
                    error: message + err500
                })
            })
        },
        _getMeetup({ commit }) {
            commit('setMeetup', {
                loading: true,
                total: 0,
                ongoing: 0,
                error: ''
            })
            apiClient.get(`authority/meetup/number`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {

                var payload = {
                    total: res.data.data.total_meetup,
                    ongoing: res.data.data.ongoing_meetup,
                }
                // console.log(payload)
                commit('setMeetup', {
                    loading: false,
                    data: payload
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setMeetup', {
                    loading: false,
                    total: 0,
                    ongoing: 0,
                    error: message + err500
                })
            })
        },
        _getTopTerbanyak({ commit }) {
            commit('setTopTerbanyak', {
                loading: true,
                data: {},
                error: ''
            })
            apiClient.get(`authority/meetup/top_region`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {

                commit('setTopTerbanyak', {
                    loading: false,
                    data: res.data.data
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setTopTerbanyak', {
                    loading: false,
                    data: {},
                    error: message + err500
                })
            })
        },
        _getTopStatus({ commit }) {
            commit('setTopStatus', {
                loading: true,
                data: {},
                error: ''
            })
            apiClient.get(`authority/meetup/top_status`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {

                commit('setTopStatus', {
                    loading: false,
                    data: res.data.data
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setTopStatus', {
                    loading: false,
                    data: {},
                    error: message + err500
                })
            })
        },
    }
}
import apiClient from "@/services/api"
import tokenService from "@/services/tokenService"

export default {
    namespaced: true,
    state: {
        menunggu_persetujuan: {
            loading: false,
            firts_load: true,
            last_record: false,
            filter: {
                limit: 10,
                search: '',
            },
            list: [],
            meta: {},
            error: '',
            detail: {
                loading: false,
                data: null,
                message: '',
                error: ''
            },
            approved: {
                loading: false,
                data: null,
                message: '',
                error: ''
            },
            rejected: {
                loading: false,
                data: null,
                message: '',
                error: ''
            }
        },
        rejected: {
            loading: false,
            firts_load: true,
            last_record: false,
            filter: {
                limit: 10,
                search: '',
            },
            list: [],
            meta: {},
            error: '',
            detail: {
                loading: false,
                data: null,
                message: '',
                error: ''
            },
        }
    },
    mutations: {
        // Event menunggu persetujuan
        setListMenungguPersetujuan(state, payload) {
            state.menunggu_persetujuan.loading = payload.loading
            state.menunggu_persetujuan.last_record = payload.last_record
            if (payload.list != undefined) {
                if (payload.firts_load) {
                    state.menunggu_persetujuan.list = payload.list
                } else {
                    state.menunggu_persetujuan.list.push(...payload.list)
                }
            }
            state.menunggu_persetujuan.meta = payload.meta
            if (payload.error != undefined) {
                state.menunggu_persetujuan.error = payload.error
            }
        },
        setDetailMenungguPersetujuan(state, payload) {
            state.menunggu_persetujuan.detail.loading = payload.loading
            state.menunggu_persetujuan.detail.data = payload.data
            if (payload.error != undefined) {
                state.menunggu_persetujuan.detail.error = payload.error
            }
        },
        setApproved(state, payload) {
            state.menunggu_persetujuan.approved.loading = payload.loading
            state.menunggu_persetujuan.approved.data = payload.data
            state.menunggu_persetujuan.approved.message = payload.message
            if (payload.error != undefined) {
                state.menunggu_persetujuan.approved.error = payload.error
            }
        },
        setRejected(state, payload) {
            state.menunggu_persetujuan.rejected.loading = payload.loading
            state.menunggu_persetujuan.rejected.data = payload.data
            state.menunggu_persetujuan.rejected.message = payload.message
            if (payload.error != undefined) {
                state.menunggu_persetujuan.rejected.error = payload.error
            }
        },
        setListRejected(state, payload) {
            state.rejected.loading = payload.loading
            state.rejected.last_record = payload.last_record
            if (payload.list != undefined) {
                if (payload.firts_load) {
                    state.rejected.list = payload.list
                } else {
                    state.rejected.list.push(...payload.list)
                }
            }
            state.rejected.meta = payload.meta
            if (payload.error != undefined) {
                state.rejected.error = payload.error
            }
        },
        setDetailRejected(state, payload) {
            state.rejected.detail.loading = payload.loading
            state.rejected.detail.data = payload.data
            if (payload.error != undefined) {
                state.rejected.detail.error = payload.error
            }
        },
    },
    actions: {
        _getListMenungguPersetujuan({ commit }, params) {
            var search = params != undefined && params.search != undefined ? params.search : ''
            var status = params != undefined && params.status != undefined ? params.status : ''
            var limit = params != undefined && params.limit != undefined ? params.limit : 10
            var cursor = params != undefined && params.meta != undefined ? params.meta : ''
            var firts_load = params != undefined && params.firts_load != undefined ? params.firts_load : true
            var firts_load = params != undefined && params.firts_load != undefined ? params.firts_load : true
            var type = params != undefined && params.type != undefined ? params.type : 'submission'

            commit('setListMenungguPersetujuan', {
                loading: true,
                firts_load: firts_load,
                list: [],
                meta: {},
                error: '',
            })

            cursor = cursor.next_cursor != undefined ? cursor.next_cursor : ''
            apiClient.get(`authority/happening/${type}/list?status=${status}&search=${search}&limit=${limit}&cursor=${cursor}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                // console.log(res)
                var last_record = false;
                if (res.data.meta.pagination.next_cursor === null) {
                    last_record = true
                }

                commit('setListMenungguPersetujuan', {
                    loading: false,
                    list: res.data.data,
                    meta: res.data.meta,
                    firts_load: firts_load,
                    last_record: last_record
                })
            }).catch(({ message, response }) => {
                // console.log('catch response :', response)
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setListMenungguPersetujuan', {
                    loading: false,
                    firts_load: firts_load,
                    error: message + err500
                })
            })
        },
        _getDetailMenungguPersetujuan({ commit }, id) {
            // var id = params != undefined && params.id != undefined ? params.id : ''

            commit('setDetailMenungguPersetujuan', {
                loading: true,
                data: null,
                error: ''
            })
            apiClient.get(`authority/happening/submission/detail?id=${id}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                commit('setDetailMenungguPersetujuan', {
                    loading: false,
                    data: res.data.data
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setDetailMenungguPersetujuan', {
                    loading: false,
                    data: null,
                    error: message + err500
                })
            })
        },
        _setApproved({ commit }, id) {

            commit('setApproved', {
                loading: true,
                data: null,
                message: '',
                error: ''
            })

            var bodyFormData = new FormData();
            bodyFormData.append('id', id)
            apiClient.post(`authority/happening/submission/approve`, bodyFormData, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                console.log(res.data)
                commit('setApproved', {
                    loading: false,
                    data: res.data.data,
                    // message: 'Event Disetujui',
                    message: res.data.message,
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setApproved', {
                    loading: false,
                    data: null,
                    message: '',
                    error: message + err500
                })
            })
        },
        _setRejected({ commit }, id) {

            commit('setRejected', {
                loading: true,
                data: null,
                message: '',
                error: ''
            })

            var bodyFormData = new FormData();
            bodyFormData.append('id', id)
            apiClient.post(`authority/happening/submission/reject`, bodyFormData, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                commit('setRejected', {
                    loading: false,
                    data: res.data.data,
                    message: 'Event Ditolak',
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setRejected', {
                    loading: false,
                    data: null,
                    message: '',
                    error: message + err500
                })
            })
        },
        _getListRejected({ commit }, params) {
            var search = params != undefined && params.search != undefined ? params.search : ''
            var status = params != undefined && params.status != undefined ? params.status : ''
            var limit = params != undefined && params.limit != undefined ? params.limit : 10
            var cursor = params != undefined && params.meta != undefined ? params.meta : ''
            var firts_load = params != undefined && params.firts_load != undefined ? params.firts_load : true
            // var firts_load = params != undefined && params.firts_load != undefined ? params.firts_load : true
            // var type = params != undefined && params.type != undefined ? params.type : 'submission'

            commit('setListRejected', {
                loading: true,
                firts_load: firts_load,
                list: [],
                meta: {},
                error: '',
            })

            cursor = cursor.next_cursor != undefined ? cursor.next_cursor : ''
            apiClient.get(`authority/happening/rejected/list?search=${search}&limit=${limit}&cursor=${cursor}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                // console.log(res)
                var last_record = false;
                if (res.data.meta.pagination.next_cursor === null) {
                    last_record = true
                }

                commit('setListRejected', {
                    loading: false,
                    list: res.data.data,
                    meta: res.data.meta,
                    firts_load: firts_load,
                    last_record: last_record
                })
            }).catch(({ message, response }) => {
                // console.log('catch response :', response)
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setListRejected', {
                    loading: false,
                    firts_load: firts_load,
                    error: message + err500
                })
            })
        },
        _getDetailRejected({ commit }, id) {
            // var id = params != undefined && params.id != undefined ? params.id : ''

            commit('setDetailRejected', {
                loading: true,
                data: null,
                error: ''
            })
            apiClient.get(`authority/happening/rejected/detail?id=${id}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                commit('setDetailRejected', {
                    loading: false,
                    data: res.data.data
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setDetailRejected', {
                    loading: false,
                    data: null,
                    error: message + err500
                })
            })
        },
    },
    getters: {
        menunggupersetujuan(state) {
            return state.menunggu_persetujuan
        },
        detailmenunggupersetujuan(state) {
            return state.menunggu_persetujuan.detail
        },
        approved(state) {
            return state.menunggu_persetujuan.approved
        },
        rejected(state) {
            return state.menunggu_persetujuan.rejected
        },
        list_rejected(state) {
            return state.rejected
        },
        detail_list_rejected(state) {
            return state.rejected.detail
        },
    }
}
import VueGoogleMaps from "@fawmi/vue-google-maps";

/**
 * Initialize Vue Google Maps component
 * @param app vue instance
 */
export function initVueGoogleMaps(app) {
  app.use(VueGoogleMaps, {
    load: {
      // key: "AIzaSyCUELNLaxh6wW3n0Qmw32_Him6AUtKButg",
      key: process.env.VUE_APP_API_GOOGLE_MAPS,
      libraries: "places",
    },
  });
}

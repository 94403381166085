import apiClient from "@/services/api"
import tokenService from "@/services/tokenService"

export default {
    namespaced: true,
    state: {
        pengajuanevent: {
            loading: false,
            firts_load: true,
            last_record: false,
            list: [],
            meta: {},
            error: '',
            filter: {
                limit: 10,
                search: '',
                category_id: ''
            },
            detail: {
                loading: false,
                data: {},
            },
            status: {
                loading: false,
                data: null,
                error: '',
                message: ''
            }
        },
        transfertomerchant: {
            loading: false,
            firts_load: true,
            last_record: false,
            list: [],
            meta: {},
            error: '',
            filter: {
                limit: 1,
                search: '',
                category_id: ''
            },
            detail: {
                loading: false,
                data: null,
            },
            buktitransfer: {
                loading: false,
                data: null,
                error: '',
                message: ''
            }
        },
        pengajuanpayout:{
            loading: false,
            firts_load: true,
            last_record: false,
            list: [],
            meta: {},
            error: '',
            info: {
                loading: false,
                data: {
                    saldo_nunom:0,
                    payout_all:0,
                    payout_done:0
                },
                error:''
            },
        }
    },
    mutations: {
        setListPengajuanEvent(state, payload) {
            state.pengajuanevent.loading = payload.loading
            state.pengajuanevent.last_record = payload.last_record
            state.pengajuanevent.list = payload.list
            state.pengajuanevent.meta = payload.meta
            if (payload.error != undefined) {
                state.pengajuanevent.error = payload.error
            }
        },
        setDetailPengajuanEvent(state, payload) {
            state.pengajuanevent.detail.loading = payload.loading
            state.pengajuanevent.detail.data = payload.data
            if (payload.error != undefined) {
                state.pengajuanevent.error = payload.error
            }
        },
        setStatus(state, payload) {
            state.pengajuanevent.status.loading = payload.loading
            state.pengajuanevent.status.data = payload.data
            if (payload.error != undefined) {
                state.pengajuanevent.status.error = payload.error
            }
        },

        setListTransferMerchant(state, payload) {
            state.transfertomerchant.loading = payload.loading
            state.transfertomerchant.last_record = payload.last_record
            state.transfertomerchant.list = payload.list
            state.transfertomerchant.meta = payload.meta
            if (payload.error != undefined) {
                state.transfertomerchant.error = payload.error
            }
        },
        setDetailBuktiTransfer(state, payload) {
            state.transfertomerchant.detail.loading = payload.loading
            state.transfertomerchant.detail.data = payload.data
            if (payload.error != undefined) {
                state.transfertomerchant.error = payload.error
            }
        },
        setBuktiTransfer(state, payload) {
            state.transfertomerchant.buktitransfer.loading = payload.loading
            state.transfertomerchant.buktitransfer.data = payload.data
            state.transfertomerchant.buktitransfer.message = payload.message
            if (payload.error != undefined) {
                state.transfertomerchant.buktitransfer.error = payload.error
            }
        },

        // Pengajuan Payout
        setListPengajuanPayout(state, payload) {
            state.pengajuanpayout.loading = payload.loading
            state.pengajuanpayout.last_record = payload.last_record
            state.pengajuanpayout.list = payload.list
            state.pengajuanpayout.meta = payload.meta
            if (payload.error != undefined) {
                state.pengajuanpayout.error = payload.error
            }
        },
        setInfoPayout(state, payload) {
            state.pengajuanpayout.info.loading = payload.loading
            state.pengajuanpayout.info.data = payload.data
            if (payload.error != undefined) {
                state.pengajuanpayout.info.error = payload.error
            }
        },
    },
    actions: {
        getListPengajuanEvent({ commit }, params) {
            var search = params != undefined && params.search != undefined ? params.search : ''
            var status = params != undefined && params.status != undefined ? params.status : ''
            var limit = params != undefined && params.limit != undefined ? params.limit : 10
            var cursor = params != undefined && params.meta != undefined ? params.meta : ''
            var firts_load = params != undefined && params.firts_load != undefined ? params.firts_load : true

            commit('setListPengajuanEvent', {
                loading: true,
                list: [],
                meta: {},
                error: '',
            })

            // cursor = cursor.next_cursor != undefined ? cursor.next_cursor : ''
            apiClient.get(`authority/payout/list?limit=${limit}&cursor=${cursor}&status=${status}&search=${search}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                var last_record = false;
                if (res.data.meta.pagination.next_cursor === null) {
                    last_record = true
                }

                commit('setListPengajuanEvent', {
                    loading: false,
                    list: res.data.data,
                    meta: res.data.meta,
                    firts_load: firts_load,
                    last_record: last_record
                })
            }).catch(({ message, response }) => {
                // console.log('catch response :', response)
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setListPengajuanEvent', {
                    loading: false,
                    list: [],
                    firts_load: firts_load,
                    error: message + err500
                })
            })
        },
        _getDetailPengajuanEvent({ commit }, id) {
            // var id = params != undefined && params.id != undefined ? params.id : ''

            commit('setDetailPengajuanEvent', {
                loading: true,
                data: {},
                error: ''
            })
            apiClient.get(`authority/payout/detail?id=${id}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                commit('setDetailPengajuanEvent', {
                    loading: false,
                    data: res.data.data,
                    error: ''
                })
            }).catch(({ message, response }) => {

                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setDetailPengajuanEvent', {
                    loading: false,
                    error: message + err500
                })
            })
        },
        _setStatus({ commit }, params) {

            var id = params != undefined && params.id != undefined ? params.id : ''
            var status = params != undefined && params.status != undefined ? params.status : ''
            commit('setStatus', {
                loading: true,
                data: null,
                message: '',
                error: ''
            })

            // var appFormdata = new FormData();
            // appFormdata.append('id', id)
            // appFormdata.append('status', status)
            var field = {
                id: id,
                status: status
            }

            // console.log('param : ', params)
            // console.log('appFormdata : ', appFormdata)
            apiClient.put(`authority/payout/update-status`, field, {
                headers: {
                    "Authorization": `Bearer ${tokenService.getUserToken().access_token}`,
                    // 'Content-Type': 'multipart/form-data'
                },
            }).then((res) => {
                // console.log(res.data.message)
                var msg = '';
                if (status === 'approved') {
                    msg = 'Payout berhasil DISETUJUI'
                }
                if (status === 'rejected') {
                    msg = 'Payout berhasil DITOLAK'
                }
                if (status === 'pending') {
                    msg = 'Payout berhasil Ditinjau Kembali'
                }
                commit('setStatus', {
                    loading: false,
                    data: res.data.data,
                    message: msg,
                    error: ''
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setStatus', {
                    loading: false,
                    message: '',
                    data: null,
                    error: message + err500
                })
            })
        },

        getListTransferMerchant({ commit }, params) {
            var search = params != undefined && params.search != undefined ? params.search : ''
            var status = params != undefined && params.status != undefined ? params.status : ''
            var limit = params != undefined && params.limit != undefined ? params.limit : 10
            var cursor = params != undefined && params.meta != undefined ? params.meta : ''
            var firts_load = params != undefined && params.firts_load != undefined ? params.firts_load : true
            // var pagingtype = params != undefined && params.pagingtype != undefined ? params.pagingtype : ''

            commit('setListTransferMerchant', {
                loading: true,
                list: [],
                meta: {},
                error: '',
            })

            // cursor = cursor.next_cursor != undefined ? cursor.next_cursor : ''
            // console.log(cursor)
            apiClient.get(`authority/payout/list?limit=${limit}&cursor=${cursor}&status=${status}&search=${search}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                var last_record = false;
                if (res.data.meta.pagination.next_cursor === null) {
                    last_record = true
                }

                commit('setListTransferMerchant', {
                    loading: false,
                    list: res.data.data,
                    meta: res.data.meta,
                    firts_load: firts_load,
                    last_record: last_record
                })
            }).catch(({ message, response }) => {
                // console.log('catch response :', response)
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setListTransferMerchant', {
                    loading: false,
                    list: [],
                    firts_load: firts_load,
                    error: message + err500
                })
            })
        },
        getDetailBuktiTransfer({ commit }, id) {
            // var id = params != undefined && params.id != undefined ? params.id : ''

            commit('setDetailBuktiTransfer', {
                loading: true,
                data: null,
                error: ''
            })
            apiClient.get(`authority/payout/detail?id=${id}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                commit('setDetailBuktiTransfer', {
                    loading: false,
                    data: res.data.data,
                    error: ''
                })
            }).catch(({ message, response }) => {

                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setDetailBuktiTransfer', {
                    loading: false,
                    error: message + err500
                })
            })
        },
        _setBuktiTransfer({ commit }, params) {

            var id = params != undefined && params.id != undefined ? params.id : ''
            var file = params != undefined && params.file != undefined ? params.file : ''
            var note = params != undefined && params.note != undefined ? params.note : ''

            commit('setBuktiTransfer', {
                loading: true,
                data: null,
                message: '',
                error: ''
            })

            var appFormdata = new FormData();
            appFormdata.append('id', id)
            appFormdata.append('file', file)
            appFormdata.append('note', note)

            // console.log('param : ', params)
            // console.log('appFormdata : ', appFormdata)
            apiClient.post(`authority/payout/upload-transfer`, appFormdata, {
                headers: {
                    "Authorization": `Bearer ${tokenService.getUserToken().access_token}`,
                    'Content-Type': 'multipart/form-data'
                },
            }).then((res) => {

                commit('setBuktiTransfer', {
                    loading: false,
                    data: res.data.data,
                    message: 'Bukti transfer payout berhasil terkirim',
                    error: ''
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setBuktiTransfer', {
                    loading: false,
                    message: '',
                    data: null,
                    error: message + err500
                })
            })
        },

        // Pengajuan Payout
        getListPengajuanPayout({ commit }, params) {
            var search = params != undefined && params.search != undefined ? params.search : ''
            var status = params != undefined && params.status != undefined ? params.status : ''
            var limit = params != undefined && params.limit != undefined ? params.limit : 10
            var cursor = params != undefined && params.meta != undefined ? params.meta : ''
            var firts_load = params != undefined && params.firts_load != undefined ? params.firts_load : true

            commit('setListPengajuanPayout', {
                loading: true,
                list: [],
                meta: {},
                error: '',
            })

            // cursor = cursor.next_cursor != undefined ? cursor.next_cursor : ''
            apiClient.get(`partner/payout/list?limit=${limit}&cursor=${cursor}&status=${status}&search=${search}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                var last_record = false;
                if (res.data.meta.pagination.next_cursor === null) {
                    last_record = true
                }

                commit('setListPengajuanPayout', {
                    loading: false,
                    list: res.data.data,
                    meta: res.data.meta,
                    firts_load: firts_load,
                    last_record: last_record
                })
            }).catch(({ message, response }) => {
                // console.log('catch response :', response)
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setListPengajuanPayout', {
                    loading: false,
                    list: [],
                    firts_load: firts_load,
                    error: message + err500
                })
            })
        },
        getInfoPayout({ commit }) {

            commit('setInfoPayout', {
                loading: true,
                data: null,
                error: ''
            })
            apiClient.get(`partner/payout/info`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                commit('setInfoPayout', {
                    loading: false,
                    data: res.data.data,
                    error: ''
                })
            }).catch(({ message, response }) => {

                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setInfoPayout', {
                    loading: false,
                    error: message + err500
                })
            })
        },
    },
    getters: {
        pengajuanevent(state) {
            return state.pengajuanevent
        },
        detailpengajuanevent(state) {
            return state.pengajuanevent.detail
        },
        statuspengajuanevent(state) {
            return state.pengajuanevent.status
        },
        transfertomerchant(state) {
            return state.transfertomerchant
        },
        detailtransfertomerchant(state) {
            return state.transfertomerchant.detail
        },
        buktitransfer(state) {
            return state.transfertomerchant.buktitransfer
        },

        // Pengajuan Payout
        pengajuanpayout(state) {
            return state.pengajuanpayout
        },
        infopayout(state) {
            return state.pengajuanpayout.info
        },
    }
}
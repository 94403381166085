import SectionAppService from "@/services/sectionAppService";

export default {
  namespaced: true,
  state: {
    isOnEditing: false,
    listCategory: [],
    listSectionApp: { data: [], pagination: {} },
    formInput: null,
    modalSectionApp: {
      isModalShow: false,
      currentData: [],
    },
    listActiveHappening: { data: [], pagination: {} },
  },
  mutations: {
    setListCategory(state, data) {
      state.listCategory = data;
    },
    setListActiveHappening(state, data) {
      state.listActiveHappening.data = data;
    },
    setPaginationListActiveHappening(state, data) {
      state.listActiveHappening.pagination = data;
    },
    setListSectionApp(state, data) {
      state.listSectionApp.data = data;
    },
    setPaginationListAdmin(state, data) {
      state.listSectionApp.pagination = data;
    },
    getDetailSectionApp(state, data) {
      state.listSectionApp.data = data;
    },
    toggleOnEditing(state) {
      state.isOnEditing = !state.isOnEditing;
    },
    toggleModalSectionApp(state) {
      state.modalSectionApp.currentData = null;
      state.modalSectionApp.isModalShow = !state.modalSectionApp.isModalShow;
    },
    setFormInput(state, data) {
      state.formInput = data;
    },
    setEditDataModalSectionApp(state, data) {
      state.modalSectionApp.currentData = data;
    },
    moveCurrentData(state, data) {
      state.modalSectionApp.currentData = data;
    },
  },
  actions: {
    getCategoryList({ commit }) {
      return SectionAppService.getListCategory()
        .then((response) => {
          commit("setListCategory", response.data.data);
          return response;
        })
        .catch((error) => {
          console.error(error);
          return error;
        });
    },

    getListActiveHappening({ commit }) {
      return SectionAppService.getListActiveHappening()
        .then((response) => {
          commit("setListActiveHappening", response.data.data);
          commit(
            "setPaginationListActiveHappening",
            response.data.meta.pagination
          );
          return response;
        })
        .catch((error) => {
          console.error(error);
          return error;
        });
    },

    getListSectionApp({ commit }) {
      return SectionAppService.getListSectionApp()
        .then((response) => {
          commit("setListSectionApp", response.data.data);
          commit("setPaginationListAdmin", response.data.meta.pagination);
          return response;
        })
        .catch((error) => {
          console.error(error);
          return error;
        });
    },

    getDetailSectionApp({ commit }, payload) {
      return SectionAppService.getDetailSectionApp(payload)
        .then((response) => {
          commit("toggleOnEditing");
          commit("setFormInput", response.data.data);
          return response;
        })
        .catch((error) => {
          console.error(error);
          return error;
        });
    },

    createSectionApp({}, payload) {
      return SectionAppService.createSectionApp(payload)
        .then((response) => {
          return response;
        })
        .catch((error) => console.error(error));
    },

    updateSectionApp({}, payload) {
      return SectionAppService.updateSectionApp(payload)
        .then((response) => {
          return response;
        })
        .catch((error) => console.error(error));
    },

    deleteSectionApp({}, payload) {
      return SectionAppService.deleteSectionApp(payload)
        .then((response) => {
          return response;
        })
        .catch((error) => console.error(error));
    },

    setFormInput({ commit }, payload) {
      commit("setFormInput", payload);
    },

    setClearFormInput({ commit }) {
      commit("setFormInput", null);
    },

    setClearDataModalSectionApp({ commit }) {
      commit("setEditDataModalSectionApp", []);
    },

    setDataModalSectionApp({ commit }, payload) {
      commit("setEditDataModalSectionApp", payload);
    },

    setEditDataModalSectionApp({ commit }, payload) {
      commit("toggleModalSectionApp");
      commit("setEditDataModalSectionApp", payload);
    },

    toggleOnEditing({ commit }) {
      return commit("toggleOnEditing");
    },

    toggleModalSectionApp({ commit }) {
      return commit("toggleModalSectionApp");
    },

    moveCurrentData({ commit }, data) {
      return commit("moveCurrentData", data);
    },
  },
};

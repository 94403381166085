<template>
    <div class="pb-12">
        <div class="mx-auto max-w-7xl ">
            <div class="overflow-hidden bg-white">
                
                <div class=" mx-auto prose lg:prose-xl prose-h2:text-gray-600">
                    <div>
                        <router-link class="text-medium-blue" to="/">
                            <img src="@/assets/mobooking-logo-blue.png" width="300" alt="Logo" />
                        </router-link>
                    </div>
                    <h2>
                        Syarat Penggunaan Mobooking
                    </h2>

                    <!--
                    <p class="font-bold">Terakhir diubah pada: Desember 2021</p>
                    
                    <p class="text-red-400">MOHON UNTUK MEMBACA SELURUH SYARAT DAN KETENTUAN PENGGUNAAN DENGAN CERMAT DAN SEKSAMA SEBELUM MENGGUNAKAN SETIAP FITUR DAN/ATAU LAYANAN YANG TERSEDIA DALAM PLATFORM MOBOOKING.</p>

                    <p>Syarat dan Ketentuan ini merupakan perjanjian antara Pengguna (“Anda”) dan MOBOOKING (“Kami”), sebuah Platform teknologi karya anak bangsa yang dimiliki oleh MOBOOKING yang beroperasi secara sah berdasarkan hukum Negara Republik Indonesia dan berdomisili di Jakarta Indonesia.</p>

                    <p>Syarat dan Ketentuan ini mengatur Anda pada tata cara dan persyaratan penggunaan fitur dan/atau layanan aplikasi MOBOOKING. Dengan mengunduh dan/atau memasang dan/atau menggunakan Platform dan/atau menikmati Layanan Kami, Anda setuju bahwa Anda telah membaca, memahami, mengetahui, menerima, dan menyetujui seluruh informasi, syarat-syarat, dan ketentuan-ketentuan penggunaan Platform yang terdapat dalam Ketentuan Penggunaan ini.</p>

                    <p>Mohon untuk dapat diperhatikan pula bahwa Ketentuan Penggunaan dapat diperbarui dari waktu ke waktu.</p>
                    -->
                    <p>
                        Selamat datang dan terima kasih telah mengunjungi dan mengunduh Mobooking. Sebelum mulai menggunakan layanan di dalam Platform dan/atau Aplikasi Lunak Mobooking (“<b>Mobooking</b>”) dan seluruh fitur di dalamnya (“<b>Fitur</b>”) pastikan Anda membaca Syarat Pengguna Akhir (“<b>Syarat Penggunaan</b>”) yang tertera pada halaman ini dengan teliti dan hati-hati.
                    </p>
                    <p>
                        Syarat Penggunaan mengatur akses Anda terhadap Mobooking yang dikembangkan oleh <span class="text-blue">Mobokiing Indonesia</span>. Dengan mendaftar atau menggunakan Mobooking, pengguna dianggap telah membaca, memahami, menerima, dan menyetujui semua isi yang terdapat pada Syarat Penggunaan ini. Jika Anda tidak menyetujui Syarat Penggunaan ini, maka Anda tidak diperkenankan mengakses layanan dan/atau menggunakan Fitur Mobooking.
                    </p>
                    <ol>
                        <li>
                            <p><b>Definisi</b></p>
                            <p>1.1 Mobooking adalah aplikasi karya anak bangsa yang dikelola oleh Mobokiing Indonesia, suatu Perseroan Terbatas yang menciptakan, mengelola, dan mengembangkan platform Mobooking sebagai penyedia usaha jasa web portal. www.mobooking.id beserta turunannya, seperti platform untuk mengelola bisnis dan penjualan yang dimiliki oleh Merchant Partner.</p>
                                <p>1.2 Platform/Aplikasi adalah www.mobooking.id beserta platform turunan milik Mobooking, namun tidak terbatas pada www.mobooking.id yang dapat diakses melalui aplikasi berbasis Android atau iOS, mobile web, dan/atau dekstop site</p>
                                <p>1.3 Pengguna Akhir adalah pihak yang menggunakan layanan Mobooking, termasuk namun tidak terbatas pada pihak lain yang mengunduh, mengunjungi, mendaftar sebagai member, dan/atau mengakses Mobooking</p>
                                <p>1.4 Merchant Partner adalah pengguna yang bertindak sebagai  Pemilik Usaha atau Penjual yang melakukan tindakan buka toko, dan/atau melakukan penawaran Jasa atau Barang kepada pengguna akhir Mobooking, termasuk pihak ketiga yang bekerja sama dengan Mobooking untuk menyediakan Barang Fisik, Barang Digital, dan/atau Jasa secara diluar jaringan atau melalui Platform Mobooking.</p>
                                <p>1.5. Pemerintah Republik Indonesia adalah Pihak Kementerian, Instansi, dan/atau Lembaga yang menggunakan Mobooking untuk kebutuhan penanganan pandemi.</p>
                                <p>1.6 Barang Fisik adalah barang yang memiliki fisik yang memenuhi kriteria pengiriman oleh perusahaan penyedia jasa layanan pengiriman</p>
                                <p>1.7 Barang Digital adalah barang yang tidak memiliki nilai fisik, termasuk namun tidak terbatas pada voucher digital (tiket masuk wisata, pertandingan, layanan umum, tagihan, dll).</p>
                        </li>
                        <li>
                            <b>Umum</b>
                            <p>2.1 Mobooking hanya dapat digunakan oleh Pengguna yang telah mendaftarkan diri, menyetujui Syarat Penggunaan ini serta yang sudah diverifikasi sesuai kebijakan dari Mobokiing Indonesia</p>
                                <p>2.2 Pengguna dengan ini menyatakan bahwa Pengguna adalah orang yang cakap dan mampu untuk mengikatkan dirinya dalam sebuah perjanjian yang sah menurut hukum.</p>
                                <p>2.3 Mobooking berhak menggunakan data Pengguna untuk memberikan informasi yang akurat dan tepat pada saat pendaftaran akun agar dapat mengakses dan/atau menggunakan fitur atau layanan Mobooking antara lain berupa; Nomor Induk Kependudukan; Nama Lengkap; dan
                                Nomor Handphone.</p>
                                <p>2.4 Mobooking berhak untuk menggunakan data Pengguna untuk penelusuran indikasi tindakan manipulasi, pelanggaran, maupun pemanfaatan Fitur Mobooking untuk keuntungan pribadi Pengguna maupun indikasi kecurangan atau pelanggaran Syarat Penggunaan ini, Ketentuan Platform, dan/atau ketentuan hukum yang berlaku di wilayah negara Indonesia.</p>
                                <p>2.5 Mobooking berhak menolak pendaftaran Pengguna jika Pengguna memberikan informasi atau data pribadi yang tidak benar. tidak valid, tidak lengkap, termasuk menangguhkan atau memberhentikan sebagian atau seluruh layanan Mobooking yang diberikan kepada pengguna.</p>
                                <p>2.6 Dalam rangka mendukung Pemerintah melakukan tindakan mitigasi resiko penyebaran COVID-19, pengguna dengan ini menyetujui dan mengizinkan Mobooking untuk mengintegrasikan data serta informasi yang diberikan kepada Mobooking.</p>
                                <p>2.7 Mobooking tidak memungut biaya pendaftaran kepada Pengguna.</p>
                                <p>2.8 Mobooking berhak melakukan perubahan atas Syarat Penggunaan.</p>
                        </li>
                        <li>
                            <b>Jaminan Keamanan dan Layanan</b>
                            <p>
                                3.1 Mobooking Indonesia bertanggung jawab dalam menjamin keamanan sistem Mobooking. Jika terjadi kegagalan atau gangguan sistem yang diakibatkan penyalahgunaan Platform/Aplikasi sebagaimana diatur dalam peraturan perundang-undangan yang dilakukan oleh Pengguna dan/atau Pihak Lain terhadap Mobooking, maka Kami akan segera melaporkan kepada penegak hukum.
                            </p>
                            <p>
                                3.2 Dalam hal Pengguna mendapatkan celah keamanan sistem Mobooking, Pengguna wajib melaporkan secara tertulis melalui <span class="text-blue">kontak@mobooking.id</span> dan dilarang memanfaatkan hal tersebut untuk kepentingan pribadi, kelompok, atau umum dengan alasan apapun.
                            </p>
                        </li>
                        <li>
                            <b>Ganti Rugi dan Batasan Tanggung Jawab</b>
                            <p>
                                4.1 Kami selalu berupaya maksimal untuk menjaga Mobooking tetap aman dan nyaman digunakan. Tapi akan ada kemungkinan waktu dimana sistem Mobooking sementara tidak dapat diakses, dikarenakan proses update dan/atau kemungkinan lain seperti problem teknis. Jika ini terjadi, proses penyelesaian kendala akan dijalankan secepat mungkin.
                            </p>
                            <p>
                                4.2 Segala kerusakan yang terjadi pada perangkat Pengguna, jaringan komputer, telepon seluler, atau aplikasi akibat penggunaan Platform/Aplikasi merupakan tanggung jawab Pengguna sepenuhnya.
                            </p>
                            <p>
                                4.3 Pengguna akan melepaskan Mobooking dari tuntutan ganti rugi dan menjaga Mobooking (termasuk Induk Perusahaan, direktur, dan karyawan) dari setiap klaim atau tuntutan, termasuk biaya hukum yang wajar, yang dilakukan oleh pihak ketiga yang timbul dalam hal Pengguna melanggar Perjanjian ini, penggunaan Platform/Aplikasi yang tidak semestinya dan/atau pelanggaran Pengguna terhadap hukum atau hak-hak pihak ketiga.
                            </p>
                        </li>
                        <li>
                            <b>Larangan</b>
                            <p>Pengguna dilarang:</p>
                            <p>5.1 Dengan sengaja dan tanpa hak atau melawan hukum melakukan intersepsi atau penyadapan Mobooking atas transmisi informasi elektronik dan/ atau dokumen elektronik milik Pengguna lainnya, Penerima, atau Kami; Seperti dengan sengaja menggunakan program atau robot, scraper, atau cara otomatis untuk mengakses, memonitor, menyebarluaskan informasi data dan/atau konten apapun di Mobooking.</p>
                            <p>5.2 Melakukan penyalahgunaan Mobooking atau Fitur dan Layanan di dalamnya untuk tujuan apapun yang melanggar hukum.</p>
                            <p>5.3 Melakukan tindakan yang mengakibarkan stress, crash, overload server Mobooking sehingga menghambat pengoperasian Platform/Aplikasi.</p>
                            <p>5.4 Kami memiliki hak untuk menggugat secara perdata maupun melakukan proses hukum secara pidana atas seluruh perbuatan yang dilarang sebagaimana dimaksud dalam Pasal ini maupun Syarat dan Ketentuan ini secara keseluruhan</p>
                        </li>
                        <li>
                            <b>Hak Kekayaan Intelektual</b>
                            <p>Platform, Fitur, merek layakan, merek dagang, logo, ikon, gambar, nuansa, tampilan, tulisan, video, konten, source code, dan materi lainnya dalam Mobooking dilindungi oleh Hak Kekayaan Intelektual berdasarkan Hukum yang berlaku. Seluruh hak, kepemilikan dan kepentingan dalam Materi adalah milik Mobooking seluruhnya yang tidak boleh ditiru, disalin, atau digunakan baik keseluruhan atau sebagian tanpa perjanjian tertulis dari Kami.</p>
                        </li>
                        <li>
                            <b>Kontak Kami (Feedback)</b>
                            <p>7.1 Segala bentuk tanggapan, saran, atau temuan yang diberikan Pengguna terkait Platform, Fitur dan/atau Layanan Mobooking dianggap tidak bersifat rahasia. Maka Mobooking berhak menggunakan informasi ini secara bebas.</p>
                            <p>7.2 Dalam hal Anda mengalami kendala, memiliki pertanyaan, permintaan, atau kendala terkait penggunaan Mobooking, Anda dapat menghubungi kami melalui <span class="text-blue">kontak@mobooking.id</span> atau telepon ke nomor <span class="text-blue">[hotline]</span> untuk mendapatkan respon instan.</p>
                        </li>
                        <li>
                            <b>Peraturan Berlaku</b>
                            <p>Syarat Penggunaan ini diatur oleh dan ditafsirkan sesuai dengan hukum Republik Indonesia, tanpa memperhatikan pertentangan aturan hukum. Pengguna menyetujui bahwa tindakan hukum apapun atau sengketa yang mungkin timbul dari, berhubungan dengan, atau berada dalam cara apapun berhubungan dengan Mobooking akan diselesaikan dalam yurisdiksi pengadilan Republik Indonesia.</p>
                        </li>
                        <li>
                            <b>Penyelesaian Perselisihan</b>
                            <p>Seluruh perselisihan yang muncul antara Para Pihak sepakat untuk menyelesaikan perselisihan dalam pelaksanaan Syarat Penggunaan ini secara musyawarah dan mufakat, dengan jangka waktu 30 (tiga puluh) hari kalender atau suatu jangka waktu lainnya sebagaimana disepakati para pihak terhitung sejak timbulnya perselisihan, maka para pihak sepakat menyelesaikan perselisihan tersebut melalui Pengadilan Negeri Jakarta.</p>
                        </li>
                        <li>
                            <b>Kebijakan Privasi</b>
                            <p>Kami akan selalu menjaga kerahasiaan informasi pribadi Pengguna mengacu pada Syarat dan Ketentuan Hukum yang berlaku, Pengguna dapat melihat lebih lanjut dalam menu Kebijakan Privasi.</p>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>
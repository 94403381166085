import Axios from "axios";

const apiClient = Axios.create({
  baseURL: process.env.VUE_APP_API_HOST_URL,//"https://admin-dev.nunom.id/api/",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  timeout: 20000,
});

export default apiClient;

import api from "./api";

export default {
  // Menu
  getListMenu() {
    return api.get("/authority/menu/list");
  },
  getDetailMenu(id) {
    return api.get(`/authority/menu/detail?id=${id}`);
  },
  createMenu(payload) {
    return api.post("/authority/menu/create", payload);
  },
  updateMenu(payload) {
    return api.post("/authority/menu/update", payload);
  },
  deleteMenu(payload) {
    return api.post("/authority/menu/delete", payload);
  },
};

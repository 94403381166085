import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./assets/tailwind.css";
import { initApexCharts } from "./core/plugins/apexcharts";
import { initSmartTable } from "./core/plugins/vue-smart-table";
import { initVueGoogleMaps } from "./core/plugins/vue-google-maps";
import moment from "moment";
import VueLoading from 'vue-loading-overlay';
// import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CKEditor from '@ckeditor/ckeditor5-vue';

moment.locale('id');
const app = createApp(App);

// initLoadingOverlay(app);
initApexCharts(app);
initSmartTable(app);
initVueGoogleMaps(app);
app.component("Loading", VueLoading);
// app.component("CKEditor", CKEditor);
// app.use(Loading)
app.use(VueLoading);
app.use(CKEditor);
app.use(store).use(router).mount("#app");

import api from "./api";
import TokenService from "@/services/tokenService";

export default {
  login({ username, password }) {
    return api.post("/authority/auth/login/password", { username, password, fcm: "98h32f98h32f9832hf" });
  },
  getProfile() {
    api.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getUserToken().access_token}`;
    return api.get("/authority/auth/profile");
  },
};

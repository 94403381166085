import MenuService from "@/services/menuService";

export default {
  namespaced: true,
  state: {
    listMenu: [],
    modalMenu: {
      isModalShow: false,
      currentData: null,
    },
  },
  mutations: {
    getListMenu(state, data) {
      state.listMenu = data;
    },
    setEditDataModalMenu(state, data) {
      state.modalMenu.currentData = data;
    },
    toggleModalMenu(state) {
      state.modalMenu.currentData = null;
      state.modalMenu.isModalShow = !state.modalMenu.isModalShow;
    },
  },
  actions: {
    getListMenu({ commit }) {
      return MenuService.getListMenu()
        .then((response) => {
          commit("getListMenu", response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createMenu({}, payload) {
      return MenuService.createMenu(payload).catch((error) => console.log(error));
    },
    getDetailMenu({ commit }, payload) {
      return MenuService.getDetailMenu(payload)
        .then((response) => {
          commit("toggleModalMenu");
          commit("setEditDataModalMenu", response.data.data);
        })
        .catch((error) => console.log(error));
    },
    updateMenu({}, payload) {
      return MenuService.updateMenu(payload).catch((error) => console.log(error));
    },
    deleteMenu({}, payload) {
      return MenuService.deleteMenu(payload).catch((error) => console.log(error));
    },
    setEditDataModalMenu({ commit }, payload) {
      commit("toggleModalMenu");
      commit("setEditDataModaMenu", payload);
    },
    toggleModalMenu({ commit }) {
      return commit("toggleModalMenu");
    },
  },
};

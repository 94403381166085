import apiClient from "@/services/api"
import tokenService from "@/services/tokenService"
import moment from "moment"

export default {
    namespaced: true,
    state: {
        pengajuan: {
            loading: false,
            event: 0,
            merchant: 0,
            error: ''
        },
        infosebaran: {
            loading: false,
            confirmed: 0,
            active: 0,
            recover: 0,
            death: 0,
            error: ''
        },
        bisnisaktifitas: {
            loading: false,
            data: null,
            error: ''
        },
        chartpandemi: {
            loading: false,
            data: {
                categories: [],
                series: []
            },
            error: ''
        },
        chartbisnis: {
            loading: false,
            data: {
                categories: [],
                series: []
            },
            error: ''
        }
    },
    mutations: {
        setPengajuan(state, payload) {
            state.pengajuan.loading = payload.loading
            state.pengajuan.event = payload.event
            state.pengajuan.merchant = payload.merchant
            if (payload.error != undefined) {
                state.pengajuan.error = payload.error
            }
        },
        setInfoSebaran(state, payload) {
            state.infosebaran.loading = payload.loading
            state.infosebaran.confirmed = payload.confirmed
            state.infosebaran.active = payload.active
            state.infosebaran.recover = payload.recover
            state.infosebaran.death = payload.death
            if (payload.error != undefined) {
                state.infosebaran.error = payload.error
            }
        },
        setBisnisAktifitas(state, payload) {
            state.bisnisaktifitas.loading = payload.loading
            state.bisnisaktifitas.data = payload.data
            if (payload.error != undefined) {
                state.bisnisaktifitas.error = payload.error
            }
        },
        setChartPandemi(state, payload) {
            state.chartpandemi.loading = payload.loading
            state.chartpandemi.data = payload.data
            if (payload.error != undefined) {
                state.chartpandemi.error = payload.error
            }
        },
        setChartBisnis(state, payload) {
            state.chartbisnis.loading = payload.loading
            state.chartbisnis.data = payload.data
            if (payload.error != undefined) {
                state.chartbisnis.error = payload.error
            }
        },
    },
    actions: {
        _getPengajuan({ commit }) {
            commit('setPengajuan', {
                loading: true,
                event: 0,
                merchant: 0,
                error: ''
            })
            apiClient.get(`authority/dashboard/submission`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                let payload = res.data.data

                commit('setPengajuan', {
                    loading: false,
                    event: payload.event_submission_total,
                    merchant: payload.merchant_submission_total,
                    error: ''
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setPengajuan', {
                    loading: false,
                    error: message + err500
                })
            })
        },
        _getInfoSebaran({ commit }, params) {
            var district = params != undefined && params.district != undefined ? params.district : ''
            var village = params != undefined && params.village != undefined ? params.village : ''

            commit('setInfoSebaran', {
                loading: true,
                event: 0,
                merchant: 0,
                error: ''
            })
            apiClient.get(`authority/dashboard/pandemic_info?district=${district}&village=${village}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                let payload = res.data.data
                commit('setInfoSebaran', {
                    loading: false,
                    confirmed: payload.confirmed,
                    active: payload.active,
                    recover: payload.recover,
                    death: payload.death,
                    error: ''
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setInfoSebaran', {
                    loading: false,
                    error: message + err500
                })
            })
        },
        _getBisnisAktifitas({ commit }) {
            commit('setBisnisAktifitas', {
                loading: true,
                data: null,
                error: ''
            })
            apiClient.get(`authority/dashboard/business_number`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                let payload = res.data.data
                commit('setBisnisAktifitas', {
                    loading: false,
                    data: payload,
                    error: ''
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setBisnisAktifitas', {
                    loading: false,
                    error: message + err500
                })
            })
        },
        _getChartPandemi({ commit }, params) {
            var filter_type = params != undefined && params.filter_type != undefined ? params.filter_type : ''
            var date_type = params != undefined && params.date_type != undefined ? params.date_type : ''

            commit('setChartPandemi', {
                loading: true,
                data: {
                    categories: [],
                    series: []
                },
                error: ''
            })
            apiClient.get(`authority/dashboard/pandemic_chart?filter_type=${filter_type}&date_type=${date_type}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                let data = res.data.data
                let categories_before = [];
                let series_before = [];
                let categories_current = [];
                let series_current = [];
                let categories = [];

                if (data.before?.length > 0) {
                    for (let index = 0; index < data.before.length; index++) {
                        categories_before.push(moment(data.before[index].date).format('l'))
                        series_before.push(data.before[index].count)
                    }
                } else {
                    if (data.current?.length > 0) {
                        for (let index = 0; index < data.current?.length; index++) {
                            series_before.push(0)
                        }
                    } else {
                        series_before.push(0)
                    }
                }

                if (data.current?.length > 0) {
                    for (let index = 0; index < data.current.length; index++) {
                        categories_current.push(moment(data.current[index].date).format('l'))
                        series_current.push(data.current[index].count)
                    }
                } else {
                    if (data.before?.length > 0) {
                        for (let index = 0; index < data.before?.length; index++) {
                            series_current.push(0)
                        }
                    } else {
                        series_current.push(0)
                    }
                }
                categories = categories_before.concat(categories_current.filter((item) => categories_before.indexOf(item) < 0))

                let payload = {
                    categories: categories,
                    series: [
                        {
                            name: 'Bulan Lalu',
                            data: series_before
                        },
                        {
                            name: 'Bulan Ini',
                            data: series_current
                        }
                    ]
                }

                commit('setChartPandemi', {
                    loading: false,
                    data: payload,
                    error: ''
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setChartPandemi', {
                    loading: false,
                    error: message + err500
                })
            })
        },
        _getChartBisnis({ commit }, params) {
            var filter_type = params != undefined && params.filter_type != undefined ? params.filter_type : ''
            var date_type = params != undefined && params.date_type != undefined ? params.date_type : ''

            commit('setChartBisnis', {
                loading: true,
                data: {
                    categories: [],
                    series: []
                },
                error: ''
            })
            apiClient.get(`authority/dashboard/business_chart?filter_type=${filter_type}&date_type=${date_type}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {
                let data = res.data.data
                let categories_before = [];
                let series_before = [];
                let categories_current = [];
                let series_current = [];
                let categories = [];

                if (data.before?.length > 0) {
                    for (let index = 0; index < data.before.length; index++) {
                        categories_before.push(moment(data.before[index].date).format('l'))
                        series_before.push(data.before[index].count)
                    }
                } else {
                    if (data.current?.length > 0) {
                        for (let index = 0; index < data.current?.length; index++) {
                            series_before.push(0)
                        }
                    } else {
                        series_before.push(0)
                    }
                }

                if (data.current?.length > 0) {
                    for (let index = 0; index < data.current.length; index++) {
                        categories_current.push(moment(data.current[index].date).format('l'))
                        series_current.push(data.current[index].count)
                    }
                } else {
                    if (data.before?.length > 0) {
                        for (let index = 0; index < data.before?.length; index++) {
                            series_current.push(0)
                        }

                    } else {
                        series_current.push(0)
                    }

                }
                categories = categories_before.concat(categories_current.filter((item) => categories_before.indexOf(item) < 0))
                // console.log('categories_before : ', categories_before)
                // console.log('categories_current : ', categories_current)
                // console.log('categories : ', categories)

                let payload = {
                    categories: categories,
                    series: [
                        {
                            name: 'Bulan Lalu',
                            data: series_before
                        },
                        {
                            name: 'Bulan Ini',
                            data: series_current
                        }
                    ]
                }
                // console.log(payload)
                commit('setChartBisnis', {
                    loading: false,
                    data: payload,
                    error: ''
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setChartBisnis', {
                    loading: false,
                    error: message + err500
                })
            })
        },
    },
    getters: {
        pengajuan(state) {
            return state.pengajuan
        },
        infosebaran(state) {
            return state.infosebaran
        },
        bisnisaktifitas(state) {
            return state.bisnisaktifitas
        },
        chartpandemi(state) {
            return state.chartpandemi
        },
        chartbisnis(state) {
            return state.chartbisnis
        },

    },
}
import api from "./api";

export default {
  // Roles
  getRoles() {
    return api.get("/authority/role/list");
  },
  getDetailRole(id) {
    return api.get(`/authority/role/detail?id=${id}`);
  },
  createRole(payload) {
    return api.post("/authority/role/create", payload);
  },
  updateRole(payload) {
    return api.post("/authority/role/update", payload);
  },
  deleteRole(payload) {
    return api.post("/authority/role/delete", payload);
  },

  // Menu
  getMenu() {
    return api.get("/authority/menu/list");
  },
};
